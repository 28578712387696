import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowLeft } from "react-bootstrap-icons";
import styles from "./BlogPosts.module.scss";
import { Link } from "react-router-dom";
import Image from "../Image/Image";
import ShareLinks from "../ShareLinks/ShareLinks";
import MetaTags from "./MetaTags";
import AOS from "aos";
import "aos/dist/aos.css";

import header from "../../assets/images/blogs/blog3/header.webp";
import body1 from "../../assets/images/blogs/blog3/body1.webp";
import body2 from "../../assets/images/blogs/blog3/body2.webp";
// meta tags

// og image 1200px X 630px
import ogImage from "../../assets/images/blogs/blog3/og_image.jpg";
const config = {
  title: " Laugh worries away",
  subtitle: "Mental Health Awareness Month",
  dateCreated: "27.May 2022",
  metaTitle: "Nearshore GM |  Laugh worries away",
  siteName: "Nearshore GM",
  keywords:
    "software engineers, software developer, software company, flyeralarm, IT, Nis, Serbia, mental health month",
  // update this
  description:
    "May is dedicated to making mental health a global priority for all.",
  url: "https://www.nearshoregm.com/insights/mental-health-month-2022",
  type: "article",
  ogImage: ogImage,
};
// meta tags end
const BlogPost1 = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page} data-aos="fade-up">
      <MetaTags config={config} />

      <figure className={styles.hero_image_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.filter}></div>
              <Image
                url={header}
                alt={config.title}
                className={`img-fluid ${styles.image}`}
              />
              <div className={styles.info}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className={styles.date_share}>
                        <span className={styles.date}>
                          {config.dateCreated}
                        </span>
                        <ShareLinks shareUrl={config.url} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className={styles.title_holder}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <h1 className={styles.title}>{config.title} </h1>
                      <h3 className={styles.subtitle}>{config.subtitle}</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </figure>
      <div className={styles.post_body}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.wraper}>
                <h2
                  className={styles.sub_title}
                  style={{ textAlign: "center" }}
                >
                  <i> Allow yourself to slow down and just have fun!</i>
                </h2>
                <p className={styles.text}>
                  May is dedicated to making mental health a global priority for
                  all. We usually spend the end of working Fridays playing
                  games, so we decided for the last Friday in May to go out of
                  the office and enjoy the beautiful spring day and celebrate
                  Mental health month in our way.
                </p>
                <div className={styles.body_image_holder}>
                  <img
                    src={body1}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  Adding some sweets and chocolates made this good idea even
                  better. We chose Association and Code names this time and it
                  turned out to be an excellent choice! Some of us had trouble
                  reading others' handwriting and tried to explain misread, made
                  up words, but this made us laugh even more. We think we found
                  the team's favorite game!
                </p>

                {/* <h4 className={styles.quote}></h4> */}
                <div className={styles.body_image_holder}>
                  <img
                    src={body2}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>

                <p className={styles.text}>
                  We like to work hard on solving problems and feel fulfilled
                  after finishing big tasks, but it is also very important to
                  find good balance and use some moments during the working day
                  to just sit with your colleagues, joke and laugh. This Friday
                  we want to emphasize that it is okay to make mistakes, to have
                  bad days, to be ourselves and to say when some of us have a
                  hard time aloud. It definitely is easier when you have support
                  and assistance at work by good teammates.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.back_link_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <Link to="/insights" className={styles.back_link}>
                <ArrowLeft className={styles.icon} />
                Back
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogPost1;
