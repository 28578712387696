import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowLeft } from "react-bootstrap-icons";
import styles from "./BlogPosts.module.scss";
import { Link } from "react-router-dom";
import Image from "../Image/Image";
import ShareLinks from "../ShareLinks/ShareLinks";
import MetaTags from "./MetaTags";
import AOS from "aos";
import "aos/dist/aos.css";

import header from "../../assets/images/blogs/blog6/header.webp";
import body1 from "../../assets/images/blogs/blog6/body1.webp";
import body2 from "../../assets/images/blogs/blog6/body2.webp";
import body3 from "../../assets/images/blogs/blog6/body3.webp";
import body4 from "../../assets/images/blogs/blog6/body4.webp";
import body5 from "../../assets/images/blogs/blog6/body5.webp";
// meta tags
// og image 1200px X 630px
import ogImage from "../../assets/images/blogs/blog6/og_image.jpg";

const config = {
  title: "Turn on relax mode",
  subtitle: "Team building 2022",
  dateCreated: "8.Nov 2022",
  metaTitle: "Nearshore GM | Turn on relax mode!",
  siteName: "Nearshore GM",
  keywords:
    "software engineers, software developer, software company, flyeralarm, IT, Nis, Serbia,team building",
  // update this
  description:
    "The month of October is dedicated to the organization of Team Building in Nearshore",
  url: "https://www.nearshoregm.com/insights/team-building-2022",
  type: "article",
  ogImage: ogImage,
};
// meta tags end
const BlogPost1 = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page} data-aos="fade-up">
      <MetaTags config={config} />
      <figure className={styles.hero_image_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.filter}></div>
              <Image
                url={header}
                alt={config.title}
                className={`img-fluid ${styles.image}`}
              />
              <div className={styles.info}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className={styles.date_share}>
                        <span className={styles.date}>
                          {config.dateCreated}
                        </span>
                        <ShareLinks shareUrl={config.url} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className={styles.title_holder}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <h1 className={styles.title}>{config.title}</h1>
                      <h3 className={styles.subtitle}>{config.subtitle}</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </figure>
      <div className={styles.post_body}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.wraper}>
                <h2 className={styles.sub_title}>
                  We love to start the Autumn season with team building. This
                  October was traditionally booked for team fun, and colorful
                  leaves, cool breeze and beautiful sunny sky made us even more
                  excited.
                </h2>
                <div className={styles.body_image_holder}>
                  <img
                    src={body1}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  This year our host was Hotel Sunce in Sokobanja and we are
                  delighted with the overall ambiance and service. The huge SPA
                  center, with swimming pools, salt room, and different types of
                  saunas only increased our satisfaction and it helped with
                  relaxation.
                </p>
                <div className={styles.body_image_holder}>
                  <img
                    src={body2}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  Two days of complete enjoyment was just what we needed. Of
                  course, there were a lot of games, and with the games, a lot
                  of laughter. And along with the games, we used the opportunity
                  to practice team communication, information transfer and
                  problem solving skills!
                </p>
                <div className={styles.body_image_holder}>
                  <img
                    src={body3}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                {/* <h4 className={styles.quote}></h4> */}
                <p className={styles.text}>
                  We had a great time resolving tasks together. No better way to
                  improve our conversation abilities and sharpen our listening
                  skills than playing games in a fun and challenging yet
                  low-pressure environment!
                </p>
                <div className={styles.body_image_holder}>
                  <img
                    src={body4}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  When there is this kind of team energy and synergy between us,
                  every place is ideal for team building. We can say that in
                  addition to team building, this event was an ideal opportunity
                  for team bonding. We learned a lot about each other, which is
                  the basis for building trust in the team.
                </p>{" "}
                <div className={styles.body_image_holder}>
                  <img
                    src={body5}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  The weekend is behind us, but many great stories are made and
                  will be told over the beer over a long period. We learn every
                  day that happiness is working with great people, and we are
                  excited about new gatherings and interesting team buildings
                  ahead of us.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.back_link_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <Link to="/insights" className={styles.back_link}>
                <ArrowLeft className={styles.icon} />
                Back
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogPost1;
