import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./PrivacyPolicy.module.scss";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ogImage from "../../assets/images/ogDefault.jpg";

const PrivacyPolicy = () => {
  return (
    <div className={styles.page}>
      <HelmetProvider>
        <Helmet>
          <title>Nearshore GM | Privacy Policy</title>
          <meta
            name="keywords"
            content="software engineers, software developer, software company, flyeralarm, Nis, Serbia, hiring, job, IT"
          />
          <meta
            name="description"
            content="This privacy notice will inform you as to how we look after your personal data when you visit our website and 
            tell you about your privacy rights."
          />
          <link
            rel="canonical"
            href="https://www.nearshoregm.com/privacy-policy"
          ></link>

          <meta property="og:type" content="website" />
          <meta property="og:title" content="Nearshore GM | Privacy Policy" />
          <meta
            property="og:description"
            content="This privacy notice will inform you as to how we look after your personal data when you visit our website and 
            tell you about your privacy rights."
          />
          <meta property="og:image" content={ogImage} />
          <meta
            property="og:url"
            content={`https://www.nearshoregm.com/privacy-policy`}
          />
          <meta property="og:site_name" content="Nearshore GM" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="Nearshore GM" />
        </Helmet>
      </HelmetProvider>
      <Container>
        <Row>
          <Col md={12}>
            <h1 className={styles.title}>Privacy Policy</h1>
          </Col>
          <Col md={12}>
            <h2 className={styles.subtitle}>Introduction</h2>
            <p className={styles.text}>
              Nearshore GM doo, located in Niš, Bulevar Svetog Cara Konstantina
              80, respects the privacy and takes the protection of personal data
              very seriously. This Privacy Policy applies to all individuals who
              access our Website https://www.nearshoregm.com/ and contains
              information about how we process your personal data in the context
              of this Website.
            </p>
            <p className={styles.text}>
              Nearshore GM doo is the controller and responsible for your
              personal data. In case you have any questions regarding this
              Privacy Policy, please contact us at: <b>info@nearshoregm.com</b>
            </p>
            <p className={styles.text}>
              This Privacy Policy is in accordance with the Law on Personal Data
              Protection adopted in November 2018 and came into effect in August
              2019 (harmonized with the GDPR).
            </p>
          </Col>
          <Col md={12}>
            <h2 className={styles.subtitle}>
              What kind of data do we collect through our Website?
            </h2>
            <p className={styles.text}>
              Information you leave on the <b>Careers page</b>
            </p>
            <ul className={styles.list}>
              <li className={styles.items}>
                Information you provide us while applying for Open positions -
                First name, maiden name, last name, username or similar
                identifier, marital status, date of birth, gender, email
                addresses, telephone numbers, or any other personal information
                you leave in your Biography (Curriculum Vitae) or Cover Letter.{" "}
              </li>
              <li className={styles.items}>
                Information you provide us while filling out the Questionnaire -
                First name, maiden name, last name, username or similar
                identifier, email addresses, or any other personal information
                you leave in the Questionnaire which is set as the first step in
                the selection process.
              </li>
            </ul>
            <p className={styles.text}>
              Information you leave on the <b>Contact us page</b>
            </p>
            <ul className={styles.list}>
              <li className={styles.items}>
                Information you use while filling out the Question form - First
                name, maiden name, last name, username or similar identifier,
                email addresses, or any other personal information you leave in
                the sent message.
              </li>
            </ul>
          </Col>
          <Col md={12}>
            <h2 className={styles.subtitle}>
              Why do we collect personal data?
            </h2>

            <ul className={styles.list}>
              <li className={styles.items}>
                First name, maiden name, last name, username or similar
                identifier, marital status, date of birth, gender, email
                addresses, telephone numbers, or any other personal information
                you leave in your Biography (Curriculum Vitae), Cover Letter, or
                Questionnaire - To process your application for open working
                positions.
              </li>
              <li className={styles.items}>
                First name, maiden name, last name, username or similar
                identifier, email addresses, or any other personal information
                you leave in the sent message - To respond to the inquiries you
                sent us via the Contact page on our Website or any other e-mail
                address available on our Website.
              </li>
            </ul>
            <p className={styles.text}>
              By giving your consent you allow us to use your personal data for
              the reasons listed above. Your personal data is not a statutory or
              a contractual requirement and you may refuse to disclose any data.
              You have the right to withdraw your consent at any time, without
              affecting the lawfulness of the processing based on consent prior
              to such withdrawal.
            </p>
            <p className={styles.text}>
              We would like to inform you that we do not process personal data
              outside the specified purposes, such as selling or disclosing
              personal data to other marketers, or providing your personal data
              to any company at any time unless strictly compelled to do so by
              law.
            </p>
          </Col>
          <Col md={12}>
            <h2 className={styles.subtitle}>
              How long do we keep your personal data?
            </h2>
            <p className={styles.text}>
              We do not keep your personal data longer than the time it takes to
              fulfill the purpose for which is collected, or no longer than the
              time for which we have your consent.
            </p>

            <p className={styles.text}>
              In determining data retention periods, we take into consideration
              local laws, contractual obligations, and the expectations and
              requirements of our partner Flyeralarm. When we don’t longer need
              your personal information, or when you request us to delete it,
              where this is legal, we will securely delete or destroy it in
              accordance with the applicable law.
            </p>
            <p className={styles.text}>
              If you have submitted your application for an open position in our
              company by sending your CV and Cover Letter via{" "}
              <b>info@nearshoregm.com</b>, or any other email available on the
              Website, we will erase your Personal Data maximum within a 3 years
              after your application has been received unless you become one of
              our team members.
            </p>
          </Col>
          <Col md={12}>
            <h2 className={styles.subtitle}>
              Who do we share your personal data with?
            </h2>
            <p className={styles.text}>
              Processing certain personal data requires the engagement of
              external processors such as our partner Flyeralarm. This
              information is shared exclusively for the purpose of processing
              your application for a currently open position via the Website, in
              terms of organizing the technical interview with colleagues
              working at Flyeralarm.
            </p>

            <p className={styles.text}>
              We may be legally required to share certain information, including
              your personal data, with e.g. public authorities or governmental
              bodies. In such a case, we will not require your further consent
              in order to share your personal data in such circumstances.
            </p>
            <p className={styles.text}>
              In order to achieve the highest level of personal data security,
              our staff is legally bound by the professional secret.
              Furthermore, we use all legal, technical and organizational
              measures to prevent any disclosed information is revealed outside
              the legal purpose for which was collected..
            </p>
          </Col>
          <Col md={12}>
            <h2 className={styles.subtitle}>
              Do we process the personal data of subjects under the age of 15?
            </h2>
            <p className={styles.text}>
              Our Website is not intended for children, and we do not knowingly
              collect data relating to children. If you are under the age of 15,
              please do not provide any personal data about yourself. If we
              detect that we have collected personal data from a person under
              the age of 15, such information will be deleted promptly. If you
              believe we have collected personal data from a person under the
              age of 15, please contact us at: <b>info@nearshoregm.com</b>
            </p>
          </Col>
          <Col md={12}>
            <h2 className={styles.subtitle}>
              What are your rights as an Individual whose personal data we hold?
            </h2>
            <ul className={styles.list}>
              <li className={styles.items}>
                The right to be informed about collecting and using your
                personal data.
              </li>
              <li className={styles.items}>
                The right to access the personal data we hold about you.
              </li>
              <li className={styles.items}>
                The right to have your personal data rectified if any of them is
                incomplete or inaccurate.
              </li>
              <li className={styles.items}>
                The right to be forgotten, to ask us to delete or dispose of any
                of your personal data that we have.
              </li>
              <li className={styles.items}>
                The right to the restriction of processing of your personal
                data.
              </li>
              <li className={styles.items}>
                The right to data portability, in case you have provided your
                personal data to us and that data is processed using automated
                means, your right is to ask us for a copy of that personal data.
              </li>
              <li className={styles.items}>
                The right to object to using your personal data for a particular
                purpose.
              </li>
            </ul>
          </Col>
          <Col md={12}>
            <h2 className={styles.subtitle}>
              How do we keep your personal data secured?
            </h2>
            <p className={styles.text}>
              We use appropriate technical and organizational measures to
              protect the personal data that we collect and process. The
              measures we use are designed to provide a level of security
              appropriate to the risk of processing your personal data.
            </p>
            <p className={styles.text}>
              However, no method of transmission over the Internet or method of
              electronic storage is 100% secure. Therefore, we cannot guarantee
              its absolute security or confidentiality.
            </p>
            <p className={styles.text}>
              If you have any questions about security on our Website or the
              protective measures, you can contact us at:{" "}
              <b>info@nearshoregm.com</b>
            </p>
          </Col>
          <Col md={12}>
            <h2 className={styles.subtitle}>Changes to our Privacy Policy</h2>
            <p className={styles.text}>
              This Privacy Policy may be subject to change from time to time, in
              line with legislation or industry developments. We will not
              explicitly inform our Website users of these changes. Instead, we
              recommend that you check this page occasionally for any policy
              changes. The Privacy Policy was last updated on June 1, 2022.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
