import React, { useEffect } from "react";
import Hero from "../../components/Hero/Hero";
import styles from "./Home.module.scss";
import PartnerStory from "./PartnerStory/PartnerStory";
import HomeNews from "./News/HomeNews";
import Hiring from "./Hiring/Hiring";
import HomeContact from "./Contact/HomeContact";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AOS from "aos";
import "aos/dist/aos.css";
import hub_img from "../../assets/images/think_innovative.jpg";
import ogImage from "../../assets/images/ogDefault.jpg";

function Home() {
  useEffect(() => {
    AOS.init({ duration: 800 });
  }, []);
  return (
    <div className={styles.page}>
      <HelmetProvider>
        <Helmet>
          <title>Nearshore GM | Tech is our comfort zone</title>
          <meta
            name="keywords"
            content="software engineers, software developer, software company, flyeralarm, Nis, Serbia, hiring, job, IT"
          />
          <meta
            name="description"
            content="NEARSHORE GM is dedicated to delivering clean and efficient solutions and services for the respectful partner, 
            with integrity and security, using modern, proven and up-to-date technologies"
          />
          <link rel="canonical" href="https://www.nearshoregm.com/"></link>

          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Nearshore GM | Tech is our comfort zone"
          />
          <meta
            property="og:description"
            content="NEARSHORE GM is dedicated to delivering clean and efficient solutions and services for the respectful partner, 
            with integrity and security, using modern, proven and up-to-date technologies."
          />
          <meta property="og:image" content={ogImage} />
          <meta property="og:url" content={`https://www.nearshoregm.com/`} />
          <meta property="og:site_name" content="Nearshore GM" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="Nearshore GM" />
        </Helmet>
      </HelmetProvider>

      <Hero />
      {/* event card */}
      <div className={styles.event_holder} id="event" hidden>
        <div className={styles.event}>
          <div className={styles.text_holder}>
            <h3 className={styles.title}>
              Open House Day - <i>Thursday, June 16, 4:00PM - 6:00PM</i>
            </h3>
            <p className={styles.location}>
              Location: Think Innovative Hub, Bul. Svetog Cara Konstantina 80,
              Nis
            </p>
            <br />
            <p className={styles.text}>
              Nearshore GM is organizing an Open House Day for the first time!
            </p>
            <p className={styles.text}>
              Come and meet our teams, find out about our current projects and
              about our tech stack!
            </p>
            <p className={styles.text}>
              The event will start at 4 PM, with drinks and food, a corner for
              games and entertainment, with a soccer table, table tennis, Sony
              play station.
            </p>
            <p className={styles.text}>
              At 5 PM there will be a presentation by our colleague{" "}
              <span className={styles.red}>Vladislav Dimitrijevic</span>, during
              which we will present one of our teams, their projects and the
              technologies they use.
            </p>
            <p className={styles.text}>
              This is an ideal opportunity to hear first hand what it is like to
              work with us, and it is best to hear from the employees
              themselves.
            </p>
            <p className={styles.text}>
              Want to meet us? Do you have questions for us?
            </p>
            <p className={styles.text}>
              Our doors are open to everyone, but the number of places for
              presentation is limited, so those who have registered will have
              the advantage.
            </p>
            <div className={styles.buttons_holder}>
              <a
                href="https://forms.gle/4gXC5tP43zZrhYm5A"
                target="_blank"
                className={`ns_button`}
              >
                Register
              </a>
              <a
                href="https://www.linkedin.com/events/6939949496059535360/about/"
                target="_blank"
                className={`ns_button`}
              >
                Link to LinkedIn event
              </a>
            </div>
            <p className={styles.text}>
              <b>Timetable:</b>
            </p>
            <ul className={styles.list}>
              <li className={styles.items}>
                <b>4 PM</b> - Start of the event, welcome drinks and food
              </li>
              <li className={styles.items}>
                <b>4:30 PM</b> - Presentation of team and projects given by{" "}
                <span className={styles.red}>
                  Vladislav Dimitrijevic, Software Engineer
                </span>
              </li>
              <li className={styles.items}>
                <b>5:15 PM </b> - Food and fun corner (play station, darts,
                table tennis, soccer table)
              </li>
              <li className={styles.items}>
                <b>6 PM </b> - End of the event
              </li>
            </ul>
          </div>
          <img
            src={hub_img}
            alt="think innovative hub"
            className={`img-fluid ${styles.image}`}
          />
        </div>
      </div>
      {/* event card end */}
      <div className={styles.home_under_hero}>
        <PartnerStory />
        <HomeNews />
        <Hiring />
        {/* <HomeContact /> */}
      </div>
    </div>
  );
}

export default Home;
