import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowLeft } from "react-bootstrap-icons";
import styles from "./BlogPosts.module.scss";
import { Link } from "react-router-dom";
import Image from "../Image/Image";
import ShareLinks from "../ShareLinks/ShareLinks";
import MetaTags from "./MetaTags";
import AOS from "aos";
import "aos/dist/aos.css";

import header from "../../assets/images/blogs/blog4/header.webp";
import body1 from "../../assets/images/blogs/blog4/body1.webp";
import body2 from "../../assets/images/blogs/blog4/body2.webp";
import body3 from "../../assets/images/blogs/blog4/body3.webp";
// meta tags

// og image 1200px X 630px
import ogImage from "../../assets/images/blogs/blog4/og_image.jpg";
const config = {
  title: "Clean Earth, green Earth",
  subtitle: "CleanUp Day",
  dateCreated: "02.Jun 2022",
  metaTitle: "Nearshore GM | Clean Earth, green Earth",
  siteName: "Nearshore GM",
  keywords:
    "software engineers, software developer, software company, flyeralarm, IT, Nis, Serbia, ecology, the nature, cleanup day",
  // update this
  description:
    "In the action of cleaning up nature, Nearshore cleaned the well-known picnic area Kamenicki Vis",
  url: "https://www.nearshoregm.com/insights/cleanup-day-2022",
  type: "article",
  ogImage: ogImage,
};
// meta tags end
const BlogPost1 = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page} data-aos="fade-up">
      <MetaTags config={config} />
      <figure className={styles.hero_image_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.filter}></div>
              <Image
                url={header}
                alt={config.title}
                className={`img-fluid ${styles.image}`}
              />
              <div className={styles.info}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className={styles.date_share}>
                        <span className={styles.date}>
                          {config.dateCreated}
                        </span>
                        <ShareLinks shareUrl={config.url} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className={styles.title_holder}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <h1 className={styles.title}>{config.title}</h1>
                      <h3 className={styles.subtitle}>{config.subtitle}</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </figure>
      <div className={styles.post_body}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.wraper}>
                <h2
                  className={styles.sub_title}
                  style={{ textAlign: "center" }}
                >
                  <i>Nature is not a place to visit. It is home.</i>
                </h2>
                <p className={styles.text}>
                  We live and work in the beautiful city, surrounded by
                  incredible views and beautiful greenery. One of these
                  breathtaking places is Kamenicki Vis, a very popular picnic
                  spot. This is why we wanted to keep it clean and spent our
                  weekend collecting waste and garbage that was left during the
                  picnics' previous weekend.
                </p>
                <div className={styles.body_image_holder}>
                  <img
                    src={body1}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  The team rolled up the sleeves, took the garbage bags and
                  started picking the waste around the place. Sunny weather was
                  helpful too! We had a great time meeting family members of our
                  colleagues that joined us, joking around and doing something
                  good for the community we live in. The feeling was great when
                  we looked around ourselves and saw spotless nature.
                </p>

                {/* <h4 className={styles.quote}></h4> */}
                <div className={styles.body_image_holder}>
                  <img
                    src={body2}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <div className={styles.body_image_holder}>
                  <img
                    src={body3}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  All of us value the natural world and know how important it
                  is, and we hope this becomes our tradition.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.back_link_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <Link to="/insights" className={styles.back_link}>
                <ArrowLeft className={styles.icon} />
                Back
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogPost1;
