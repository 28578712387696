import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./Footer.module.scss";
import { Linkedin } from "react-bootstrap-icons";
import { mainMenuData } from "../../data/mainMenuData";
import { NavLink } from "react-router-dom";

import emailIcon from "../../assets/images/send.png";
import pinIcon from "../../assets/images/pin.png";
import linkedinIcon from "../../assets/images/linkedin.png";

import logo from "../../assets/images/logo.png";

const Footer = () => {
  return (
    <>
      <div className={styles.footer_contact}>
        <Container>
          <Row>
            <Col md={12}>
              <ul className={styles.list}>
                <li
                  className={styles.items}
                  data-aos="fade-right"
                  data-aos-once="true"
                >
                  <a
                    href="mailto:info@nearshoregm.com"
                    className={styles.links}
                  >
                    <img
                      src={emailIcon}
                      alt="email nearshore"
                      className={styles.icon}
                    />
                    <span>info@nearshoregm.com</span>
                  </a>
                </li>
                <li
                  className={styles.items}
                  data-aos="fade-up"
                  data-aos-once="true"
                >
                  <a
                    href="https://www.linkedin.com/company/nearshore-gm/"
                    target="_blank"
                    className={styles.links}
                  >
                    <img
                      src={linkedinIcon}
                      alt="email nearshore"
                      className={styles.icon}
                    />
                    <span>Nearshore GM</span>
                  </a>
                </li>
                <li
                  className={styles.items}
                  data-aos="fade-left"
                  data-aos-once="true"
                >
                  <a
                    href="https://www.google.rs/maps/place/THINK+Innovative+Hub/@43.3060793,21.9488694,19.25z/data=!4m5!3m4!1s0x4755b1688ccffb43:0xa5abcdff89ef3d9b!8m2!3d43.3062937!4d21.9485077"
                    className={styles.links}
                    target="_blank"
                  >
                    <img
                      src={pinIcon}
                      alt="email nearshore"
                      className={styles.icon}
                    />
                    <span>
                      Bulevar Svetog Cara Konstantina 80, <br /> 18000 Niš,
                      Serbia <br />
                      (Think Innovative Hub){" "}
                    </span>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <footer className={styles.footer}>
        <Container>
          <Row>
            <Col lg={8} md={7}>
              <div className={styles.left_part}>
                <div className={styles.menu}>
                  <img
                    src={logo}
                    alt="logo nearshore"
                    className={styles.logo}
                  />
                  <ul className={styles.list}>
                    {mainMenuData.map((item, index) => {
                      return (
                        <li className={styles.items} key={item.id}>
                          <NavLink to={item.path} className={styles.links}>
                            {item.title}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={4} md={5}>
              <div className={styles.right_part}>
                {/* <ul className={styles.social_list}>
                  <li className={styles.items}>
                    <a href="" target="_blank" className={styles.links}>
                      <Linkedin className={styles.icon} />
                    </a>
                  </li>
                </ul> */}
                <ul className={styles.privacy_list}>
                  <li className={styles.items}>
                    <NavLink to="/privacy-policy" className={styles.links}>
                      Privacy Policy
                    </NavLink>
                  </li>
                  <li className={styles.items}>
                    <NavLink to="/cookie-policy" className={styles.links}>
                      Cookie Policy
                    </NavLink>
                  </li>
                </ul>
                <ul className={styles.copyright_list}>
                  <li className={styles.items}>
                    Copyright 2024 &copy; Nearshore GM
                  </li>
                  <li className={styles.items}>All Rights Reserved</li>
                  <li className={styles.items} hidden>
                    <a
                      target="_blank"
                      href="https://storyset.com/work"
                      className={styles.freepik}
                    >
                      Illustration by Freepik Storyset
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
