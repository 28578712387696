import React from "react";
import styles from "./PartnerStory.module.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowRight } from "react-bootstrap-icons";
import flyeralarmImg2 from "../../../assets/images/partner2.png";
import Image from "../../../components/Image/Image";
import { Link } from "react-router-dom";

function PartnerStory() {
  return (
    <div className={`${styles.partner_story}`}>
      <Container data-aos="fade-up">
        <Row>
          <Col lg={6}>
            <h1 className={styles.title}>
              A valuable partnership with Flyeralarm
            </h1>
            <p className={styles.text}>
              Nearshore GM has become a synonym for a reliable IT company in a
              very short time by working on projects that entail the development
              and maintenance of Flyeralarm’s webshop, one of the largest in
              Europe that deals with branding all kinds of products. As
              partners, we are involved in all internal processes of development
              which are handled by our dedicated team of tech enthusiasts with
              expertise in their niche. Our partnership with Flyeralarm GmbH,
              Würzburg (DE) proves the greatness of our success and stability.
            </p>
            <Link to="/partner-story" className={styles.links}>
              Read Full Partner Story <ArrowRight className={styles.icon} />
            </Link>
          </Col>
          <Col lg={6}>
            <Image
              url={flyeralarmImg2}
              alt="flayeralarm"
              className={styles.image}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PartnerStory;
