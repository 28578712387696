import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./PartnerStory.module.scss";

import img1 from "../../assets/images/1540439864789.JPG";
import check from "../../assets/images/check.png";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AOS from "aos";
import "aos/dist/aos.css";
import headImage from "../../assets/images/Flyeralarm_Header.webp";
import ogImage from "../../assets/images/ogDefault.jpg";

const PartnerStory = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page}>
      <HelmetProvider>
        <Helmet>
          <title>Nearshore GM | About Flyeralarm</title>
          <meta
            name="keywords"
            content="software engineers, software developer, software company, flyeralarm, Nis, Serbia, hiring, job, IT"
          />
          <meta
            name="description"
            content="A story about our partner, Flyeralarm."
          />
          <link
            rel="canonical"
            href="https://www.nearshoregm.com/partner-story"
          ></link>

          <meta property="og:type" content="website" />
          <meta property="og:title" content="Nearshore GM | About Flyeralarm" />
          <meta
            property="og:description"
            content="A story about our partner, Flyeralarm."
          />
          <meta property="og:image" content={ogImage} />
          <meta
            property="og:url"
            content={`https://www.nearshoregm.com/partner-story`}
          />
          <meta property="og:site_name" content="Nearshore GM" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="Nearshore GM" />
        </Helmet>
      </HelmetProvider>
      <div className={styles.title_holder}>
        <img
          src={headImage}
          alt="flayeralarm image"
          loading="eager"
          className={`img-fluid ${styles.head_image}`}
        />
        <div className={styles.filter}></div>
        <Container>
          <Row>
            <Col md={12}>
              <h1 className={`${styles.title} section_title`}>
                About <span>Flyeralarm</span>
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.body} fluid data-aos="fade-up">
        <Container>
          <Row>
            <Col md={8}>
              <p className={styles.text}>
                <span className={styles.gray}>
                  <span className={styles.red}>Flyeralarm</span> is one of the
                  largest German e-commerce corporation and one of the leading
                  online print shop in Europe in the B2B sector.
                </span>{" "}
                <br />
                <br />
                The company was founded in 2002 at Würzburg, but it still has a
                spirit of a start-up, and today it’s present in more than 15
                European countries. It employs more than 2,400 people, from
                which 200 experts in different IT fields -{" "}
                <b>
                  {" "}
                  Software / Web development, IT support, DevOps, Security
                  specialists.
                </b>
              </p>
              <ul className={`${styles.list}`}>
                <li className={styles.item}>
                  <img src={check} alt="check" className={styles.check} />
                  <span>ESTABLISHED IN 2002</span>
                </li>
                <li className={styles.item}>
                  <img src={check} alt="check" className={styles.check} />
                  <span>PROVIDING SERVICES IN MORE THAN 15 COUNTRIES</span>
                </li>
                <li className={styles.item}>
                  <img src={check} alt="check" className={styles.check} />
                  <span>More than 2,400 employees</span>
                </li>
                <li className={styles.item}>
                  <img src={check} alt="check" className={styles.check} />
                  <span>over 3 million products</span>
                </li>
                <li className={styles.item}>
                  <img src={check} alt="check" className={styles.check} />
                  <span> More than 1.5 million customers</span>
                </li>
              </ul>
            </Col>
            <Col md={4}>
              <img
                src={img1}
                className={`${styles.image1} img-fluid`}
                alt="flayeralarm"
                loading="lazy"
              />
            </Col>

            <Col md={12}>
              <p className={styles.text}>
                Flyeralarm doesn’t only supply print products, but also
                promotional items, advertising technology, clothing & textiles,
                digital marketing and services. In total, there are over 3
                million products to choose from the Flyeralarm’s webshop. The
                DNA of Flyeralarm is printing: up to 20,000 orders are processed
                every day and up to 24,000 shipments are coordinated in such a
                way that they find their way to the customer in the shortest
                possible time. Many of them already one day after receipt of the
                order or even on the same day. The Flyeralarm service concept,
                which is now valued by more than 1.5 million customers, is
                ideally supplemented by on-site support with its branches all
                over Europe.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PartnerStory;
