import React from "react";

function Image(props) {
  return (
    <img
      src={props.url}
      alt={props.alt}
      className={`img-fluid ${props.className}`}
    />
  );
}

export default Image;
