import React, { useEffect } from "react";
import styles from "./AboutUs.module.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import icon1 from "../../assets/images/shape.png";
import icon2 from "../../assets/images/aim.png";
import icon3 from "../../assets/images/rhombus.png";
import icon4 from "../../assets/images/blur.png";

import icon5 from "../../assets/images/mission.png";
import icon6 from "../../assets/images/vision.png";
import icon7 from "../../assets/images/value.png";
import { Helmet, HelmetProvider } from "react-helmet-async";

import AOS from "aos";
import "aos/dist/aos.css";

import headerImage from "../../assets/images/about-us-header.jpg";

import ogImage from "../../assets/images/ogDefault.jpg";

const AboutUs = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page}>
      <HelmetProvider>
        <Helmet>
          <title>Nearshore GM | About Us</title>
          <meta
            name="keywords"
            content="software engineers, software developer, software company, flyeralarm"
          />
          <meta
            name="description"
            content="Everything you need to know about NEARSHORE."
          />
          <link
            rel="canonical"
            href="https://www.nearshoregm.com/about-us"
          ></link>

          <meta property="og:type" content="website" />
          <meta property="og:title" content="Nearshore GM | About Us" />
          <meta
            property="og:description"
            content="Everything you need to know about NEARSHORE."
          />
          <meta property="og:image" content={ogImage} />
          <meta
            property="og:url"
            content="https://www.nearshoregm.com/about-us"
          />
          <meta property="og:site_name" content="Nearshore GM" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="Nearshore GM" />
        </Helmet>
      </HelmetProvider>
      <div className={`page_header ${styles.header}`} data-aos="fade-up">
        <Container>
          <img
            src={headerImage}
            alt="nearshore-about-us"
            className={`img-fluid ${styles.header_image}`}
          />
          {/* <Row>
            <Col md={12}>
              <h1 className={`section_title ${styles.title}`}>
                ABOUT <span>US</span>
              </h1>
            </Col>
          </Row> */}

          <Row>
            <Col md={12}>
              <h2 className={styles.sub_title}>People</h2>
              <p className={styles.text}>
                People are our most valued strength. We are nurturing the
                culture of loyalty, teamwork and life balance at Nearshore GM.
                We are giving our employees the best possibilities to deliver
                the highest results and achieve their potential while trying to
                offer them the greatest IT experience. Our{" "}
                <b>Career Development Plan</b> is aimed at providing each
                developer with a quality onboarding process, all the necessary
                tools and resources, as well as expert guidance for further
                growth and development.
              </p>
              <p className={styles.text}>
                We try to nurture personal development with every colleague by
                having one on one meetings on regular bases where we try to meet
                long-term goals related to each person's strengths and
                potential.
              </p>
            </Col>
          </Row>
          <div className={styles.mission}>
            <Row>
              <Col md={12}>
                <h2 className={styles.sub_title}>Mission & Values</h2>
                <Row className={styles.m_row}>
                  <Col lg={6} md={8}>
                    <div
                      className={styles.box}
                      data-aos="fade-right"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-once="true"
                    >
                      <div className={styles.icon_holder}>
                        <img
                          src={icon5}
                          alt="icon"
                          className={`img-fluid ${styles.icon}`}
                        />
                      </div>
                      <div className={styles.text_holder}>
                        <h3>
                          <b>Mission statement</b>
                        </h3>
                        <p className={styles.text}>
                          Dedicated to deliver clean and efficient solutions and
                          services for respectful partner, with integrity and
                          security, using modern, proven and up-to-date
                          technologies.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={4}>
                    <div className={styles.line_holder}></div>
                  </Col>
                </Row>
                <Row className={styles.m_row}>
                  <Col lg={6} md={4}>
                    <div
                      className={`${styles.line_holder} ${styles.reversed}`}
                    ></div>
                  </Col>
                  <Col lg={6} md={8}>
                    <div
                      className={styles.box}
                      data-aos="fade-left"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-once="true"
                    >
                      <div className={styles.icon_holder}>
                        <img
                          src={icon6}
                          alt="icon"
                          className={`img-fluid ${styles.icon}`}
                        />
                      </div>
                      <div className={styles.text_holder}>
                        <h3>
                          <b>Vision statement</b>
                        </h3>
                        <p className={styles.text}>
                          Striving to be a unique and noticeable IT company, a
                          workplace of choice, by fostering honest and
                          long-lasting relationships within our team.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className={styles.m_row}>
                  <Col lg={6} md={8}>
                    <div
                      className={styles.box}
                      data-aos="fade-right"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-once="true"
                    >
                      <div className={styles.icon_holder}>
                        <img
                          src={icon7}
                          alt="icon"
                          className={`img-fluid ${styles.icon}`}
                        />
                      </div>
                      <div className={styles.text_holder}>
                        <h3>
                          <b>Value statement</b>
                        </h3>
                        <p className={styles.text}>
                          Nurturing the culture of loyalty, honesty, teamwork
                          and life balance and giving every teammate the best
                          possibility to deliver the highest results and reach
                          full potential.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={4}></Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row>
            <Col md={12}>
              <br />
              <h2 className={styles.our_values_title}>
                <b>Our values</b>
              </h2>
              <div className={styles.values_box_holder}>
                <div
                  className={styles.values_box}
                  data-aos="zoom-in-right"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-once="true"
                >
                  <h4 className={styles.title}>
                    <b>1. Enjoying honest and loyal teamwork</b>
                  </h4>
                  <p className={styles.text}>
                    Enjoying what we do and who we work with is an important
                    part of our culture. That is why we want people in our
                    company who love teamwork as much as we do. Long-term
                    cooperation is nurtured by trusting one another in finishing
                    all given tasks, respecting diversities, similarities, and
                    personal integrity.
                  </p>
                  <img src={icon1} alt="icon" className={styles.values_icon} />
                </div>
                <div
                  className={styles.values_box}
                  data-aos="zoom-in-left"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-once="true"
                >
                  <h4 className={styles.title}>
                    <b>2. Aiming for challenges </b>
                  </h4>
                  <p className={styles.text}>
                    We love a good challenge and are constantly looking for
                    them, at work and in life. Every new thing or task is
                    possible to learn. We look at failures as a success because
                    we’re always giving our best to learn from them.
                  </p>
                  <img src={icon2} alt="icon" className={styles.values_icon} />
                </div>
                <div
                  className={styles.values_box}
                  data-aos="zoom-in-left"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-once="true"
                >
                  <h4 className={styles.title}>
                    <b>3. Finding the perfect balance </b>
                  </h4>
                  <p className={styles.text}>
                    We know how difficult it is to keep the balance nowadays.
                    That is why we appreciate it so much and we do not give up
                    on it. There is no overtime work - never has been and never
                    will be. Going home tired and drained is rarely an option
                    with us because the tasks during the day are well planned,
                    therefore we have enough time for our families and friends.
                    This is something that we value the most and believe
                    strongly.
                  </p>
                  <img src={icon3} alt="icon" className={styles.values_icon} />
                </div>
                <div
                  className={styles.values_box}
                  data-aos="zoom-in-right"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-once="true"
                >
                  <h4 className={styles.title}>
                    <b>
                      4. Steady and sustainable growth in which we all
                      participate
                    </b>
                  </h4>
                  <p className={styles.text}>
                    At Nearshore GM, we are all initiators of good changes. We
                    care about our employees' opinions and like to keep everyone
                    involved in decision making. By continuous work on our
                    skills and competencies, we contribute to the growth of the
                    whole company.
                  </p>
                  <img src={icon4} alt="icon" className={styles.values_icon} />
                </div>
              </div>
              {/* 
              <h4>
                <b>1. Enjoying honest and loyal teamwork</b>
              </h4>
              <p className={styles.text}>
                Enjoying what we do and who we work with is an important part of
                our culture. That is why we want people in our company who love
                teamwork as much as we do. Long-term cooperation is nurtured by
                trusting one another in finishing all given tasks, respecting
                diversities, similarities, and personal integrity.
              </p>
              <h4>
                <b>2. Aiming for challenges </b>
              </h4>
              <p className={styles.text}>
                We love a good challenge and are constantly looking for them, at
                work and in life. Every new thing or task is possible to learn.
                We look at failures as a success because we’re always giving our
                best to learn from them.
              </p>
              <h4>
                <b>3. Finding the perfect balance </b>
              </h4>
              <p className={styles.text}>
                We know how difficult it is to keep the balance nowadays. That
                is why we appreciate it so much and we do not give up on it.
                There is no overtime work - never has been and never will be.
                Going home tired and drained is rarely an option with us because
                the tasks during the day are well planned, therefore we have
                enough time for our families and friends. This is something that
                we value the most and believe strongly.
              </p>
              <h4>
                <b>
                  4. Steady and sustainable growth in which we all participate
                </b>
              </h4>
              <p className={styles.text}>
                At Nearshore GM, we are all initiators of good changes. We care
                about our employees' opinions and like to keep everyone involved
                in decision making. By continuous work on our skills and
                competencies, we contribute to the growth of the whole company.
              </p> */}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AboutUs;
