import React, { useState } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import styles from "./BlogCard.module.scss";
import { ShareFill } from "react-bootstrap-icons";
import { ArrowRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";

function BlogCard({ imgUrl, title, subtitle, date, url }) {
  const [shareActive, setShareActive] = useState(false);
  const socilaAnimated = useSpring({
    opacity: shareActive ? 1 : 0,
    width: shareActive ? "auto" : 0,
  });
  const iconChange = useSpring({
    transform: shareActive ? "rotate(180deg)" : "rotate(0deg)",
  });
  const shareUrl = `https://www.nearshoregm.com/insights/${url}`;
  return (
    <>
      <div
        className={styles.card}
        style={{ backgroundImage: `url(${imgUrl})` }}
      >
        <div className={styles.filter}></div>
        <div className={styles.top_info}>
          <span className={styles.date}>{date}</span>
          <span
            className={styles.share}
            onClick={() => {
              setShareActive(!shareActive);
            }}
          >
            <animated.div style={iconChange}>
              {shareActive ? (
                <ArrowRight className={`${styles.icon} ${styles.font}`} />
              ) : (
                <ShareFill className={styles.icon} />
              )}
            </animated.div>
            <animated.div
              className={styles.share_buttons_holder}
              style={socilaAnimated}
            >
              <FacebookShareButton
                url={shareUrl}
                className={styles.share_button}
              >
                <FacebookIcon
                  borderRadius={20}
                  className={styles.social_icons}
                />
              </FacebookShareButton>
              <LinkedinShareButton
                url={shareUrl}
                className={styles.share_button}
              >
                <LinkedinIcon
                  borderRadius={20}
                  className={styles.social_icons}
                />
              </LinkedinShareButton>

              <TwitterShareButton
                url={shareUrl}
                className={styles.share_button}
              >
                <TwitterIcon
                  borderRadius={20}
                  className={styles.social_icons}
                />
              </TwitterShareButton>

              <ViberShareButton url={shareUrl} className={styles.share_button}>
                <ViberIcon borderRadius={20} className={styles.social_icons} />
              </ViberShareButton>

              <WhatsappShareButton
                url={shareUrl}
                className={styles.share_button}
              >
                <WhatsappIcon
                  borderRadius={20}
                  className={styles.social_icons}
                />
              </WhatsappShareButton>
            </animated.div>
          </span>
        </div>

        <Link to={`/insights/${url}`} className={styles.links}>
          <div className={styles.main_info}>
            <h3 className={styles.title}>{title}</h3>
            <h4 className={styles.subtitle}>{subtitle}</h4>
            <span className={styles.read_more}>
              read more <ArrowRight className={styles.icon} />
            </span>
          </div>
        </Link>
      </div>
    </>
  );
}

export default BlogCard;
