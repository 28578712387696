import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowLeft } from "react-bootstrap-icons";
import styles from "./BlogPosts.module.scss";
import { Link } from "react-router-dom";
import Image from "../Image/Image";
import ShareLinks from "../ShareLinks/ShareLinks";
import MetaTags from "./MetaTags";
import AOS from "aos";
import "aos/dist/aos.css";

import header from "../../assets/images/blogs/blog2/header.webp";
import body1 from "../../assets/images/blogs/blog2/body1.webp";
import body2 from "../../assets/images/blogs/blog2/body2.webp";
// meta tags

// og image 1200px X 630px
import ogImage from "../../assets/images/blogs/blog2/og_image.jpg";
const config = {
  title: "Wishing you joyful New year",
  subtitle: "",
  dateCreated: "05.Jan 2022",
  metaTitle: "Nearshore GM | Wishing you joyful New year",
  siteName: "Nearshore GM",
  keywords:
    "software engineers, software developer, software company, flyeralarm, IT, Nis, Serbia, new years 2022, celebration, Santa Claus",
  // update this
  description:
    "Every year, Think Innovative Hub organizes a gift giving ceremony for our youngest.",
  url: "https://www.nearshoregm.com/insights/new-years-celebration-2022",
  type: "article",
  ogImage: ogImage,
};
// meta tags end
const BlogPost1 = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page} data-aos="fade-up">
      <MetaTags config={config} />

      <figure className={styles.hero_image_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.filter}></div>
              <Image
                url={header}
                alt={config.title}
                className={`img-fluid ${styles.image}`}
              />
              <div className={styles.info}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className={styles.date_share}>
                        <span className={styles.date}>
                          {config.dateCreated}
                        </span>
                        <ShareLinks shareUrl={config.url} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className={styles.title_holder}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <h1 className={styles.title}>{config.title}</h1>
                      <h3 className={styles.subtitle}>{config.subtitle}</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </figure>
      <div className={styles.post_body}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.wraper}>
                <h2
                  className={styles.sub_title}
                  style={{ textAlign: "center" }}
                >
                  <i>
                    Children are one-third of our population and all of our
                    future.
                  </i>
                </h2>
                <p className={styles.text}>
                  Every year, Think Innovative Hub organizes a gift giving
                  ceremony for our youngest.
                </p>
                <p className={styles.text}>
                  And this year, all children were looking forward to this
                  event. They drew and painted, played with each other, took
                  pictures with Santa and received their presents.
                </p>
                <p className={styles.text}>
                  We have also prepared surprises for the children of our
                  employees and we hope that they will enjoy all the gifts they
                  receive for the holidays.
                </p>
                <p className={styles.text}>
                  But we adults have the right to have a little fun too!.
                </p>
                <p className={styles.text}>
                  That's why we organized gift giving, but through the Secret
                  Santa game.
                </p>
                <div className={styles.body_image_holder}>
                  <img
                    src={body1}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  We are extremely pleased with how much effort everyone put
                  into making a personalized gift for a colleague!
                </p>
                {/* <h4 className={styles.quote}></h4> */}
                <div className={styles.body_image_holder}>
                  <img
                    src={body2}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  We wish you happy holidays and a successful New Year!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.back_link_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <Link to="/insights" className={styles.back_link}>
                <ArrowLeft className={styles.icon} />
                Back
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogPost1;
