import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowLeft } from "react-bootstrap-icons";
import styles from "./BlogPosts.module.scss";
import { Link } from "react-router-dom";
import Image from "../Image/Image";
import ShareLinks from "../ShareLinks/ShareLinks";
import MetaTags from "./MetaTags";
import AOS from "aos";
import "aos/dist/aos.css";

import header from "../../assets/images/blogs/blog11/1.webp";
import body2 from "../../assets/images/blogs/blog11/2.webp";
import body3 from "../../assets/images/blogs/blog11/3.webp";
import body5 from "../../assets/images/blogs/blog11/5.webp";
import body6 from "../../assets/images/blogs/blog11/6.webp";
import body8 from "../../assets/images/blogs/blog11/8.webp";
import body9 from "../../assets/images/blogs/blog11/9.webp";
import body10 from "../../assets/images/blogs/blog11/DSC_0072.webp";

// meta tags

// og image 1200px X 630px
import ogImage from "../../assets/images/blogs/blog11/og_image.png";

const config = {
  title: "Fun filled family day at the office",
  subtitle: "Family day 2024",
  dateCreated: "16.May 2024",
  metaTitle: "Nearshore GM | Fun filled family day at the office",
  siteName: "Nearshore GM",
  keywords:
    "software engineers, software developer, software company, flyeralarm, IT, Nis, Serbia, international family day, 2024",
  // update this
  description:
    "At the Nearshore GM offices, we celebrated International Family Day.",
  url: "https://www.nearshoregm.com/insights/fun-filled-family-day-at-the-office",
  type: "article",
  ogImage: ogImage,
};
// meta tags end
const BlogPost10 = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page} data-aos="fade-up">
      <MetaTags config={config} />
      <figure className={styles.hero_image_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.filter}></div>
              <Image
                url={header}
                alt={config.title}
                className={`img-fluid ${styles.image}`}
              />
              <div className={styles.info}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className={styles.date_share}>
                        <span className={styles.date}>
                          {config.dateCreated}
                        </span>
                        <ShareLinks shareUrl={config.url} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className={styles.title_holder}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <h1 className={styles.title}>{config.title}</h1>
                      <h3 className={styles.subtitle}>{config.subtitle}</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </figure>
      <div className={styles.post_body}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.wraper}>
                <h2 className={`${styles.sub_title} ${styles.fixed_width}`}>
                  “Most of us spend too much time on what is urgent and not
                  enough time on what is important.”
                </h2>
                <p className={styles.text}>
                  This May was all about family time. With vacation time ahead
                  of us, we wanted to get a head start on spending more time
                  together. The International family day provided the perfect
                  opportunity for this celebration.
                </p>
                <p className={styles.text}>
                  We used this special day to introduce the little ones to the
                  jobs our colleagues perform. Moms and dads had plenty to share
                  about typing on keyboards, and the kids were fascinated. We
                  hope we sparked some interest in future developers and ignited
                  any other curiosities in our little ones.
                </p>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body2}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body3}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  The highlight of the day was undoubtedly the games - enjoyed
                  by kids and grown-ups alike. The wise things we heard from the
                  kids offered us fresh perspectives on work. Their simple
                  truths reminded us of the importance of perseverance,
                  expressing our thoughts freely, and embracing creativity. The
                  kids’ creativity was evident in every moment. We learned about
                  new kinds of animals living in the ocean (or maybe not), and
                  they shared their candid, amazingly creative opinions on what
                  to pack for a seaside trip.
                </p>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body5}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body8}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body9}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body6}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  We also got a glimpse into their ambitions to become the next
                  Supermans, Elsas, Wall-Es, Unicorns, or even Serbian
                  superheroes like Marko Kraljević, and we believe they’ll
                  dreams will come true. It was delightful to hear how kids
                  think outside the box.
                </p>
                <div className={`${styles.body_image_holder} `}>
                  <img
                    src={body9}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  A big thank you to everyone who made this day incredibly fun.
                  We can’t wait to hear what the kids aspire to be next year.
                  Until then, we'll share anecdotes and fond memories from this
                  year’s celebration.
                </p>
                <div className={`${styles.body_image_holder} `}>
                  <img
                    src={body10}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.back_link_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <Link to="/insights" className={styles.back_link}>
                <ArrowLeft className={styles.icon} />
                Back
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogPost10;
