import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowLeft } from "react-bootstrap-icons";
import styles from "./BlogPosts.module.scss";
import { Link } from "react-router-dom";
import Image from "../Image/Image";
import ShareLinks from "../ShareLinks/ShareLinks";
import MetaTags from "./MetaTags";
import AOS from "aos";
import "aos/dist/aos.css";

import header from "../../assets/images/blogs/blog5/header.webp";
import body1 from "../../assets/images/blogs/blog5/body1.webp";
import body2 from "../../assets/images/blogs/blog5/body2.webp";
import body3 from "../../assets/images/blogs/blog5/body3.webp";
import body4 from "../../assets/images/blogs/blog5/body4.webp";
import body5 from "../../assets/images/blogs/blog5/body5.webp";
import body6 from "../../assets/images/blogs/blog5/body6.webp";
import body7 from "../../assets/images/blogs/blog5/body7.webp";
// meta tags
// og image 1200px X 630px
import ogImage from "../../assets/images/blogs/blog5/og_image.jpg";

const config = {
  title: "Let’s meet!",
  subtitle: "Open House Day 2022",
  dateCreated: "17.Jun 2022",
  metaTitle: "Nearshore GM | Let’s meet!",
  siteName: "Nearshore GM",
  keywords:
    "software engineers, software developer, software company, flyeralarm, IT, Nis, Serbia,team building,open house day",
  // update this
  description:
    "For the first time since the founding of Nearshore GM, we decided to organize an Open House Day",
  url: "https://www.nearshoregm.com/insights/open-house-day-2022",
  type: "article",
  ogImage: ogImage,
};
// meta tags end
const BlogPost1 = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page} data-aos="fade-up">
      <MetaTags config={config} />
      <figure className={styles.hero_image_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.filter}></div>
              <Image
                url={header}
                alt={config.title}
                className={`img-fluid ${styles.image}`}
              />
              <div className={styles.info}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className={styles.date_share}>
                        <span className={styles.date}>
                          {config.dateCreated}
                        </span>
                        <ShareLinks shareUrl={config.url} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className={styles.title_holder}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <h1 className={styles.title}>{config.title}</h1>
                      <h3 className={styles.subtitle}>{config.subtitle}</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </figure>
      <div className={styles.post_body}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.wraper}>
                <h2 className={styles.sub_title}>
                  For the first time since the founding of Nearshore GM, we
                  decided to organize an Open House Day.
                </h2>
                <p className={styles.text}>
                  The first ideas of how we would organize and how it would all
                  look were slowly taking shape in reality and the day of the
                  event was slowly approaching.
                </p>
                <div className={styles.body_image_holder}>
                  <img
                    src={body1}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  We aimed to provide fun activities for all our guests and
                  employees, through games like darts, table football and xbox,
                  then through food and drinks and most importantly - through
                  networking.
                </p>
                <div className={styles.body_image_holder}>
                  <img
                    src={body2}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <div className={styles.body_image_holder}>
                  <img
                    src={body3}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                {/* <h4 className={styles.quote}></h4> */}
                <p className={styles.text}>
                  At the beginning of the event, our HR department presented
                  company culture, technologies we use, our teams and the
                  actions we had in the last period.
                </p>
                <div className={styles.body_image_holder}>
                  <img
                    src={body4}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <div className={styles.body_image_holder}>
                  <img
                    src={body5}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  One of our software engineers, <i>Vladislav</i>, presented the
                  work of his team and his project to the visitors, presented
                  the steps in his work and explained the connection of his team
                  with other teams.
                </p>
                <div className={styles.body_image_holder}>
                  <img
                    src={body6}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  We were extremely pleased to welcome such a large number of
                  people and open our doors to them, to make new acquaintances,
                  meet potential colleagues and, most importantly, have a great
                  time!
                </p>
                <div className={styles.body_image_holder}>
                  <img
                    src={body7}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  We hope that there will be more events like this in the
                  future!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.back_link_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <Link to="/insights" className={styles.back_link}>
                <ArrowLeft className={styles.icon} />
                Back
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogPost1;
