export const mainMenuData = [
  {
    id: 0,
    title: "Home",
    path: "/",
    active: false,
  },
  {
    id: 1,
    title: "About Us",
    path: "/about-us",
    active: false,
  },
  {
    id: 2,
    title: "Projects & Tech",
    path: "/technologies-and-projects",
    active: false,
  },
  // {
  //   id: 3,
  //   title: "Company culture",
  //   path: "/company-culture",
  //   active: false,
  // },
  {
    id: 4,
    title: "Insights",
    path: "/insights",
    active: false,
  },
  {
    id: 5,
    title: "Careers",
    path: "/careers",
    active: false,
  },
  {
    id: 6,
    title: "Contact Us",
    path: "/contact-us",
    active: false,
  },
];
