import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const MetaTags = ({ config }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{config.metaTitle}</title>
        <meta name="keywords" content={config.keywords} />
        <meta name="description" content={config.description} />
        <link rel="canonical" href={config.url}></link>

        <meta property="og:type" content={config.type} />
        <meta property="og:title" content={config.metaTitle} />
        <meta property="og:description" content={config.description} />
        <meta property="og:image" content={config.ogImage} />
        <meta property="og:url" content={config.url} />
        <meta property="og:site_name" content={config.siteName} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={config.title} />
      </Helmet>
    </HelmetProvider>
  );
};

export default MetaTags;
