import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BlogCard from "../../components/BlogCard/BlogCard";
import styles from "./News.module.scss";
import { blogCardsData } from "../../data/blogCardsData";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ogImage from "../../assets/images/ogDefault.jpg";

const News = () => {
  const [newsData, setNewsData] = useState(blogCardsData);
  const ui = newsData.map((post) => (
    <Col lg={4} md={6} key={post.id}>
      <BlogCard
        imgUrl={post.imageUrl}
        title={post.title}
        subtitle={post.subtitle}
        date={post.dateCreated}
        url={post.url}
      />
    </Col>
  ));

  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page}>
      <HelmetProvider>
        <Helmet>
          <title>Nearshore GM | Insights</title>
          <meta
            name="keywords"
            content="software engineers, software developer, software company, flyeralarm, Nis, Serbia, hiring, job, IT"
          />
          <meta
            name="description"
            content="Blog posts and articles from Nearshore GM about company culture, interesting stories and other news from the IT industry."
          />
          <link
            rel="canonical"
            href="https://www.nearshoregm.com/insights"
          ></link>

          <meta property="og:type" content="website" />
          <meta property="og:title" content="Nearshore GM | Insights" />
          <meta
            property="og:description"
            content="Blog posts and articles from Nearshore GM about company culture, interesting stories and other news from the IT industry."
          />
          <meta property="og:image" content={ogImage} />
          <meta
            property="og:url"
            content={`https://www.nearshoregm.com/insights`}
          />
          <meta property="og:site_name" content="Nearshore GM" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="Nearshore GM" />
        </Helmet>
      </HelmetProvider>
      <Container fluid data-aos="fade-up">
        <Row>
          {/* {blogCardsData.map((post) => {
            return (
              <Col lg={4} md={6} key={post.id}>
                <BlogCard
                  imgUrl={post.imageUrl}
                  title={post.title}
                  date={post.dateCreated}
                  url={post.url}
                />
              </Col>
            );
          })} */}
          {ui}
        </Row>
      </Container>
    </div>
  );
};

export default News;
