import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import styles from "./MobileMenu.module.scss";

const MobileMenu = (props) => {
  const [data, setData] = useState(props.mainMenuData);
  const animation = useSpring({ right: 0, from: { right: "-100%" } });
  return (
    <animated.div
      style={props.activeBurger ? animation : null}
      className={styles.mobile_menu}
    >
      <ul className={styles.list}>
        {data.map((item) => {
          return (
            <li className={styles.items} key={item.id}>
              <Link
                to={item.path}
                className={styles.links}
                onClick={() => {
                  props.handleMenuClose(false);
                }}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </animated.div>
  );
};

export default MobileMenu;
