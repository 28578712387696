import React, { useState, useEffect } from "react";
import { GeoAlt } from "react-bootstrap-icons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Input from "../../components/Input/Input";
import styles from "./Contact.module.scss";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AOS from "aos";
import "aos/dist/aos.css";
import ogImage from "../../assets/images/ogDefault.jpg";
import Loader from "../../components/Loader/Loader";

const Contact = () => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = async (e) => {
    setBtnDisabled(true);
    e.preventDefault();
    const contactFormData = new FormData();
    contactFormData.append("name", formValue.name);
    contactFormData.append("email", formValue.email);
    contactFormData.append("message", formValue.message);
    try {
      // const response = await axios({
      //   method: "post",
      //   url: "https://api.nearshoregm.com/mail/contact",
      //   data: contactFormData,
      // });
      const response = await axios({
        method: "post",
        url: "https://mailer-w6t7.onrender.com/contact/",
        data: formValue,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          background: "#35467d",
          title: "Your message is sent!",
          position: "bottom-end",
          showConfirmButton: false,
          timer: 5000,
          backdrop: false,
          toast: true,
          customClass: {
            title: "swal2-title",
          },
        });
        setFormValue({ name: "", email: "", message: "" });
        setBtnDisabled(false);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        background: "red",
        title: "Oopss...something went wrong",
        text: "Your message was not sent!",
        position: "bottom-end",
        showConfirmButton: false,
        timer: 5000,
        backdrop: false,
        toast: true,
        customClass: {
          title: "swal2-title",
        },
      });
      setBtnDisabled(false);
      console.log(error);
    }
  };
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page}>
      <HelmetProvider>
        <Helmet>
          <title>Nearshore GM | Contact Us</title>
          <meta
            name="keywords"
            content="software engineers, software developer, software company, flyeralarm, Nis, Serbia, hiring, job, IT"
          />
          <meta name="description" content="We'd love to hear from you." />
          <link
            rel="canonical"
            href="https://www.nearshoregm.com/contact-us"
          ></link>

          <meta property="og:type" content="website" />
          <meta property="og:title" content="Nearshore GM | Contact Us" />
          <meta
            property="og:description"
            content="We'd love to hear from you."
          />
          <meta property="og:image" content={ogImage} />
          <meta
            property="og:url"
            content={`https://www.nearshoregm.com/contact-us`}
          />
          <meta property="og:site_name" content="Nearshore GM" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="Nearshore GM" />
        </Helmet>
      </HelmetProvider>
      <div className="page_header" data-aos="fade-right">
        <Container>
          <Row>
            <Col md={12}>
              <h1 className={`section_title pages ${styles.contact_title}`}>
                Get in <span className={styles.title_span}>touch</span>{" "}
              </h1>
              <p className={styles.top_text}>
                Interested in working with us? Head to our{" "}
                <NavLink to="/careers" className={styles.links}>
                  Careers
                </NavLink>{" "}
                page for all our current openings and general applications.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.contact_info} data-aos="fade-up">
        <Container>
          <Row>
            <Col lg={5}>
              <div className={styles.location}>
                <h2 className={styles.title}>Location</h2>
                <h5 className={styles.sub_title}>NearshoreGM</h5>
                <p className={styles.text}>
                  80 Bulevar Svetog Cara Konstantina
                </p>
                <p className={styles.text}>18000 Nis, Serbia</p>
                <a
                  href="https://www.google.rs/maps/place/THINK+Innovative+Hub/@43.3060793,21.9488694,19.25z/data=!4m5!3m4!1s0x4755b1688ccffb43:0xa5abcdff89ef3d9b!8m2!3d43.3062937!4d21.9485077"
                  target="_blank"
                  className={`ns_button ${styles.button}`}
                >
                  Get directions
                  <GeoAlt className={styles.icon} />
                </a>
              </div>
            </Col>
            <Col md={1}></Col>
            <Col lg={6}>
              <h2 className={styles.title}>CONTACT US</h2>
              <form action="" className="form" onSubmit={handleSubmit}>
                {btnDisabled && <Loader />}
                <Input
                  type="text"
                  placeholder="..."
                  labelTitle="Name"
                  name="name"
                  value={formValue.name}
                  onChange={handleChange}
                  required={true}
                />
                <Input
                  type="email"
                  placeholder="..."
                  labelTitle="Email"
                  name="email"
                  value={formValue.email}
                  onChange={handleChange}
                  required={true}
                />
                <label className={styles.label}>
                  <span className={styles.label_span}>Message</span>
                  <textarea
                    className={styles.textarea}
                    placeholder="..."
                    name="message"
                    value={formValue.message}
                    onChange={handleChange}
                    required={true}
                  ></textarea>
                </label>
                <input
                  type="submit"
                  className={`ns_button ${styles.button}`}
                  value="Send"
                  disabled={btnDisabled}
                />
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Contact;
