import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import styles from "./PreferencesModal.module.scss";
import { Link } from "react-router-dom";

const PreferencesModal = ({
  open,
  setModal,
  gCookieDecline,
  acceptCookies,
  cookieAccepted,
}) => {
  const handleClose = () => setModal({ open: false });
  const [dropdown, setDropdown] = useState({
    essential: false,
    statistic: false,
  });
  const [allowedCookies, setAllowedCookies] = useState({
    statistic: true,
  });
  useEffect(() => {
    gCookieDecline(allowedCookies.statistic);
  }, [allowedCookies]);
  return (
    <Modal
      show={open && !cookieAccepted}
      size="lg"
      onHide={handleClose}
      contentClassName="pref_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Your Cookie Preferences</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={styles.text}>
          We use different types of cookies to optimize your experience on our
          Website. Click on the categories below to learn more about their
          purposes. You may choose which types of cookies to allow and can
          change your preferences at any time. Remember that disabling cookies
          may affect your experience on the Website. You can learn more about
          how we use cookies by visiting our{" "}
          <Link to="/cookie-policy">Cookie Policy</Link> and{" "}
          <Link to="/privacy-policy">Privacy Policy</Link>.
        </p>
        <div className={styles.cookies_type}>
          <div className={styles.check_holder}>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Essential cookies"
                checked={true}
                disabled
              />
            </Form.Group>
            <span
              className={styles.details}
              onClick={() =>
                setDropdown({
                  ...dropdown,
                  essential: !dropdown.essential,
                })
              }
            >
              (Details)
            </span>
          </div>

          <p className={styles.text}>
            These cookies are necessary to the core functionality of our Website
            and some of its features.
          </p>
          {dropdown.essential && (
            <div className={styles.details_dropdown}>
              <Table
                striped
                bordered
                responsive
                hover
                size="sm"
                className="pref_table"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Purpose</th>
                    <th>Type</th>
                    <th>Expires In</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>acceptedCookies</td>
                    <td>
                      The cookie remembers that the user is accepting all
                      cookies.
                    </td>
                    <td>HTTP</td>
                    <td>7 days</td>
                  </tr>
                  <tr>
                    <td>ga_dec</td>
                    <td>
                      This cookie remembers that the user refused to accept
                      analytics cookies.
                    </td>
                    <td>HTTP</td>
                    <td>7 days</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <div className={styles.cookies_type}>
          <div className={styles.check_holder}>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Analytics cookies"
                checked={allowedCookies.statistic}
                onChange={() =>
                  setAllowedCookies({
                    ...allowedCookies,
                    statistic: !allowedCookies.statistic,
                  })
                }
              />
            </Form.Group>
            <span
              className={styles.details}
              onClick={() =>
                setDropdown({
                  ...dropdown,
                  statistic: !dropdown.statistic,
                })
              }
            >
              (Details)
            </span>
          </div>
          <p className={styles.text}>
            These cookies collect information that can help us understand how
            our Websites are being used. This information can also measure
            effectiveness in our marketing campaigns or curate a personalized
            site experience for you.
          </p>
          {dropdown.statistic && (
            <div className={styles.details_dropdown}>
              <Table
                striped
                bordered
                responsive
                hover
                size="sm"
                className="pref_table"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Purpose</th>
                    <th>Type</th>
                    <th>Expires In</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>_ga</td>
                    <td>
                      This cookie registers a unique ID that is used to generate
                      statistical data on how the visitor uses the Website.
                    </td>
                    <td>HTTP</td>
                    <td>2 years</td>
                  </tr>
                  <tr>
                    <td>_gid</td>
                    <td>
                      This cookie is installed by Google Analytics. The cookie
                      is used to store information of how visitors use a Website
                      and helps in creating an analytics report of how the
                      Website is doing.
                    </td>
                    <td>HTTP</td>
                    <td>1 day</td>
                  </tr>
                  <tr>
                    <td>_gat</td>
                    <td>
                      This cookie is associated with Google Analytics. It is
                      used to limit the request rate–limiting the collection of
                      data on high-traffic sites
                    </td>
                    <td>HTTP</td>
                    <td>10 minutes</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={styles.pref_modal_btn_holder}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            className={`ns_button ${styles.save_btn}`}
            onClick={() => acceptCookies()}
          >
            Save and close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PreferencesModal;
