import React, { useState } from "react";
import styles from "./Header.module.scss";
import logo from "../../assets/images/logo.png";
import Image from "../../components/Image/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NavLink } from "react-router-dom";

import { mainMenuData } from "../../data/mainMenuData";
import MobileMenu from "./MobileMenu/MobileMenu";
function Header() {
  const [data, setData] = useState(mainMenuData);

  const [activeBurger, setActiveBurger] = useState(false);
  const handleMenuClose = (active) => {
    setActiveBurger(active);
  };
  if (activeBurger) {
    document.body.classList.add("scroll_hidden");
  } else {
    document.body.classList.remove("scroll_hidden");
  }

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <Container>
          <Row>
            <Col lg={3} md={9} xs={8}>
              <figure className={styles.logo_holder}>
                <NavLink to="/" className={styles.links} exact={true}>
                  <Image
                    url={logo}
                    alt="nearshore logo"
                    className={styles.logo}
                  />
                </NavLink>
              </figure>
            </Col>
            <Col lg={9} md={3} xs={4}>
              <nav className={styles.navigation}>
                <ul className={styles.list}>
                  {data.map((item, index) => {
                    return (
                      <li className={styles.items} key={item.id}>
                        <NavLink
                          to={item.path}
                          exact={true}
                          activeClassName="menu_link_active"
                          className={`${styles.links} `}
                        >
                          {item.title}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
                <div className={styles.burger_holder}>
                  <div
                    className={`${styles.burger_button_holder} ${
                      activeBurger && styles.change
                    }`}
                    onClick={() => setActiveBurger(!activeBurger)}
                  >
                    <div className={styles.bar1}></div>
                    <div className={styles.bar2}></div>
                    <div className={styles.bar3}></div>
                  </div>
                </div>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <MobileMenu
        mainMenuData={mainMenuData}
        activeBurger={activeBurger}
        handleMenuClose={handleMenuClose}
      />
    </header>
  );
}

export default Header;
