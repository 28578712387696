import React, { useState, useEffect } from "react";
import BlogCard from "../../../components/BlogCard/BlogCard";
import styles from "./HomeNews.module.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { blogCardsData } from "../../../data/blogCardsData";
import { NavLink } from "react-router-dom";

function HomeNews() {
  const [newsData, setNewsData] = useState(blogCardsData);

  const lastFourPosts = newsData.slice(0, 3);

  const ui = [].concat(lastFourPosts).map((post) => (
    <Col lg={4} md={12} sm={12} key={post.id}>
      <BlogCard
        imgUrl={post.imageUrl}
        title={post.title}
        subtitle={post.subtitle}
        date={post.dateCreated}
        url={post.url}
      />
    </Col>
  ));

  return (
    <div className={styles.news_holder}>
      <Container>
        <Row>
          <Col md={12}>
            <h2 className={`section_title ${styles.main_title}`}>Insights</h2>
          </Col>
        </Row>
      </Container>
      <Container data-aos="fade-up" data-aos-once="true">
        <Row>{ui}</Row>
        <Row>
          <Col md={12}>
            <div className={styles.button_holder}>
              <NavLink to={`/insights`} exact={true} className={`ns_button`}>
                More news
              </NavLink>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeNews;
