import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./CompanyCulture.module.scss";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Helmet, HelmetProvider } from "react-helmet-async";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import headImage from "../../assets/images/team/team2.webp";

import img1 from "../../assets/images/team/image1.webp";
import img2 from "../../assets/images/team/image2.webp";
import img3 from "../../assets/images/team/image3.webp";
import img4 from "../../assets/images/team/image4.webp";
import img5 from "../../assets/images/team/image5.webp";
import img6 from "../../assets/images/team/image6.webp";
import img7 from "../../assets/images/team/image7.webp";
import img8 from "../../assets/images/team/image8.webp";
import img9 from "../../assets/images/team/image9.webp";
import img10 from "../../assets/images/team/image10.webp";
import img11 from "../../assets/images/team/image11.webp";
import img12 from "../../assets/images/team/image12.webp";
import img13 from "../../assets/images/team/image13.webp";
import img14 from "../../assets/images/team/image14.webp";
import img15 from "../../assets/images/team/image15.webp";
import img16 from "../../assets/images/team/image16.webp";

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
];
const settings = {
  className: "center",
  infinite: true,
  // centerPadding: "60px",
  slidesToShow: 3,
  swipeToSlide: true,
  // centerMode: true,
  arrows: true,
  afterChange: function (index) {
    console.log(
      `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
    );
  },
};
const CompanyCulture = () => {
  return (
    <div className={styles.page}>
      <HelmetProvider>
        <Helmet>
          <title>Nearshore GM | Company Culture</title>
          <meta
            name="keywords"
            content="software engineers, software developer, software company, flyeralarm"
          />
          <meta
            name="description"
            content="NEARSHORE GM is dedicated to delivering clean and efficient solutions and services for the respectful partner, with integrity and security, using modern, proven and up-to-date technologies"
          />
          <link
            rel="canonical"
            href="https://www.nearshoregm.com/company-culture"
          ></link>
        </Helmet>
      </HelmetProvider>
      <div className={styles.title_holder}>
        <img
          src={headImage}
          alt="nearshore team"
          loading="eager"
          className={`${styles.head_image} img-fluid`}
        />
        <div className={styles.filter}></div>
        <Container>
          <Row>
            <Col md={12}>
              <h1 className={`${styles.title} section_title`}>
                Company <span>Culture </span>
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.body}>
        <Slider {...settings}>
          {images.map((img, index) => {
            return (
              <div key={index} className={styles.slider_images_holder}>
                <img
                  src={img}
                  alt="nearshore images"
                  className={`img-fluid ${styles.slider_image}`}
                  loading="lazy"
                />
              </div>
            );
          })}
        </Slider>
        {/* <Container>
          <Row>
            <Col md={12} className={styles.gall_holder}>
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
              >
                <Masonry columnsCount={3} gutter="10px">
                  {images.map((img, index) => {
                    return (
                      <img
                        src={img}
                        alt="nearshore images"
                        key={index}
                        loading="lazy"
                      />
                    );
                  })}
                </Masonry>
              </ResponsiveMasonry>
            </Col>
          </Row>
        </Container> */}
      </div>
    </div>
  );
};

export default CompanyCulture;
