import React from "react";
import styles from "./Hiring.module.scss";
import Image from "../../../components/Image/Image";
import { NavLink } from "react-router-dom";

import hireImg from "../../../assets/images/hiring_nearshore.webp";
import { Container, Row, Col } from "react-bootstrap";
function Hiring() {
  return (
    <div className={styles.hire_holder}>
      <Container>
        <Row>
          <Col md={6}>
            <Image
              url={hireImg}
              alt="hire"
              className={`img_fluid ${styles.image}`}
            />
          </Col>

          <Col md={6}>
            <div className={styles.text_holder}>
              <h3
                className={styles.title}
                data-aos="fade-in"
                data-aos-once="true"
              >
                We Are Hiring
              </h3>
              <div data-aos="fade-right" data-aos-once="true">
                <p className={styles.text}>
                  As a company with rapid expansion, we are in constant search
                  for motivated technology professionals who want to maximize
                  their potential. Want to know more about currently open
                  positions? Feel free to contact us.
                </p>
                <NavLink
                  to="/careers"
                  exact={true}
                  className={`ns_button ${styles.button}`}
                >
                  Apply
                </NavLink>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Hiring;
