import React from "react";
import styles from "./PageNotFound.module.scss";

const PageNotFound = () => {
  return (
    <div className={styles.page}>
      <div className={styles.text_holder}>
        <span className={styles.num}>404</span>
        <span className={styles.separator}></span>
        <span className={styles.text}>This page could not be found.</span>
      </div>
    </div>
  );
};

export default PageNotFound;
