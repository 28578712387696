import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./TechnologiesAndProjects.module.scss";
import { ArrowRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

// logos
import php from "../../assets/images/tech_logos/php.png";
import node from "../../assets/images/tech_logos/node.png";
import react from "../../assets/images/tech_logos/react.png";
import vue from "../../assets/images/tech_logos/vue.png";
import docker from "../../assets/images/tech_logos/docker.webp";
import mysql from "../../assets/images/tech_logos/mysql-logo.svg";
import mongodb from "../../assets/images/tech_logos/mongo.png";
import redis from "../../assets/images/tech_logos/redis.png";
import elastic from "../../assets/images/tech_logos/elastic-logo.svg";
import aws from "../../assets/images/tech_logos/amazon.png";
import slim from "../../assets/images/tech_logos/slim.png";
import laravel from "../../assets/images/tech_logos/laravel.png";

import introImg from "../../assets/images/pexels-cup-of-couple-6a.jpg";

import projects1 from "../../assets/images/wwt.webp";
import projects2 from "../../assets/images/payyy.jpg";

import { Helmet, HelmetProvider } from "react-helmet-async";

import AOS from "aos";
import "aos/dist/aos.css";
import ogImage from "../../assets/images/ogDefault.jpg";

const logos = [
  { src: php, title: "PHP", teams: ["ww", "mp"] },
  { src: slim, title: "Slim", teams: ["mp"] },
  { src: laravel, title: "Laravel", teams: ["ww"] },
  { src: node, title: "Node JS", teams: [] },
  { src: react, title: "React", teams: [] },
  { src: vue, title: "Vue", teams: [] },
  { src: mysql, title: "MySQL", teams: ["ww", "mp"] },
  { src: docker, title: "Docker", teams: ["ww", "mp"] },
  { src: mongodb, title: "MongoDB", teams: [] },
  { src: redis, title: "Redis", teams: [] },
  { src: elastic, title: "Elasticsearch", teams: [] },
  { src: aws, title: "AWS", teams: [] },
  { src: "", title: "And more...", teams: ["ww", "mp"] },
];

const TechnologiesAndProjects = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page}>
      <HelmetProvider>
        <Helmet>
          <title>Nearshore GM | Technologies & Projects</title>
          <meta
            name="keywords"
            content="software engineers, software developer, software company, flyeralarm, Nis, Serbia, hiring, job, IT"
          />
          <meta
            name="description"
            content="NEARSHORE GM is dedicated to delivering clean and efficient solutions and services for the respectful 
            partner, with integrity and security, using modern, proven and up-to-date technologies."
          />
          <link
            rel="canonical"
            href="https://www.nearshoregm.com/technologies-and-projects"
          ></link>

          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Nearshore GM | Technologies & Projects"
          />
          <meta
            property="og:description"
            content="NEARSHORE GM is dedicated to delivering clean and efficient solutions and services for the respectful 
            partner, with integrity and security, using modern, proven and up-to-date technologies."
          />
          <meta property="og:image" content={ogImage} />
          <meta
            property="og:url"
            content={`https://www.nearshoregm.com/technologies-and-projects`}
          />
          <meta property="og:site_name" content="Nearshore GM" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="Nearshore GM" />
        </Helmet>
      </HelmetProvider>

      <div className={styles.title_holder} data-aos="fade-down">
        <Container>
          <Row>
            <Col lg={1}></Col>
            <Col lg={5}>
              <span className={styles.intro_text}>
                The technology you use impresses no one. The experience you
                create with it is everything.
              </span>
              <Link to={`/careers`} className={styles.apply_links}>
                <span className={styles.apply}>
                  Now Hiring <ArrowRight className={styles.icon} />
                </span>
              </Link>
            </Col>
            <Col lg={5}>
              <img
                src={introImg}
                alt="projects neashore"
                loading="eager"
                className={`img-fluid ${styles.into_image}`}
              />
            </Col>
            <Col lg={1}></Col>
          </Row>
        </Container>
      </div>

      <div className={styles.body}>
        <div className={styles.default_text_holder}>
          <Container>
            <Row>
              <Col lg={12}>
                <p className={styles.default_text}>
                  <span className={styles.firstLetter}>W</span>e are working in
                  different multicultural teams with colleagues from different
                  places around the world together with Project Managers,
                  Product Owners and Team Leaders from Germany with joined
                  efforts. The workload is carefully planned so that everyone
                  can be focused on making quality products.
                  <br />
                  <a
                    href="/partner-story"
                    className={`ns_button ${styles.partner_button}`}
                  >
                    About Flyeralarm
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className={styles.sections}>
          <div className={styles.width_adjuster}>
            <Container data-aos="fade-up" data-aos-once="true">
              <Row>
                <Col lg={6} md={12}>
                  <img
                    src={projects1}
                    alt=""
                    className={`img-fluid ${styles.image}`}
                  />
                </Col>

                <Col lg={6} md={12}>
                  <p className={`${styles.text}`}>
                    <span className={styles.firstLetter}>W</span>e stay up to
                    date with the newest version of our core technology and take
                    advantage of all its features and benefits. Recent upgrade
                    to <span>PHP 8.2</span> is aimed at improving the overall
                    efficiency and stability of our apps. The same goes for
                    frameworks. We recently enhanced our team’s developer
                    experience by migrating to <span>Laravel 10</span> to ensure
                    compatibility with modern tools and libraries. We embrace{" "}
                    <span>Domain-Driven Design (DDD)</span> principles, which
                    leads to a more flexible and expressive code.
                  </p>
                  <div className={styles.logos_holder}>
                    <ul className={styles.list}>
                      {logos.map((logo, index) => {
                        if (logo.teams.includes("ww")) {
                          return (
                            <li className={styles.tech_title} key={index}>
                              {logo.title}
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className={styles.sections}>
          <div className={styles.width_adjuster}>
            <Container data-aos="fade-up" data-aos-once="true">
              <Row>
                <Col lg={6}>
                  <p className={`${styles.text} ${styles.blue_bckg}`}>
                    <span className={styles.firstLetter}>T</span>he pleasure of
                    online shopping followed by customer satisfaction is our
                    main drive. The goal is to enrich the user journey, by
                    providing a smoother purchasing process. By eliminating
                    complexities, we are increasing operational agility. New
                    features are aimed at continuous improvement of our{" "}
                    <span>payment methods</span> and system modernization.
                  </p>
                  <div className={styles.logos_holder}>
                    <ul className={styles.list}>
                      {logos.map((logo, index) => {
                        if (logo.teams.includes("mp")) {
                          return (
                            <li className={styles.tech_title} key={index}>
                              {logo.title}
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </Col>
                <Col lg={6}>
                  <img
                    src={projects2}
                    alt=""
                    className={`img-fluid ${styles.image}`}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col md={12}>
                  <p className={styles.text}>
                    As a company that started its journey 4 years ago, we are
                    extremely glad to be a part of Flyeralarm’s amazing system
                    that offers us both personal and organizational stability.
                    Our imperative is to create the best conditions for the
                    development and functioning of our partner's projects.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnologiesAndProjects;
