import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowLeft } from "react-bootstrap-icons";
import styles from "./BlogPosts.module.scss";
import { Link } from "react-router-dom";
import Image from "../Image/Image";
import ShareLinks from "../ShareLinks/ShareLinks";
import MetaTags from "./MetaTags";
import AOS from "aos";
import "aos/dist/aos.css";

import header from "../../assets/images/blogs/blog10/blog10x.webp";
import body1 from "../../assets/images/blogs/blog10/blog10b.webp";
import body2 from "../../assets/images/blogs/blog10/blog10c.webp";
import body3 from "../../assets/images/blogs/blog10/blog10d.webp";
import body4 from "../../assets/images/blogs/blog10/blog10a.webp";
import body5 from "../../assets/images/blogs/blog10/blog10n.webp";
import body6 from "../../assets/images/blogs/blog10/blog10.webp";
import body7 from "../../assets/images/blogs/blog10/blog10e.webp";
import body8 from "../../assets/images/blogs/blog10/blog10h.webp";
import body9 from "../../assets/images/blogs/blog10/blog10g.webp";
import body10 from "../../assets/images/blogs/blog10/blog10k.webp";
import body11 from "../../assets/images/blogs/blog10/blog10z.webp";
import body12 from "../../assets/images/blogs/blog10/blog10v.webp";

// meta tags

// og image 1200px X 630px
import ogImage from "../../assets/images/blogs/blog10/og_image.jpg";

const config = {
  title: "Wrapping up the year together",
  subtitle: "365 new days, 365 new chances. Let's make each one count.",
  dateCreated: "08.Jan 2024",
  metaTitle: "Nearshore GM | Wrapping up the year together",
  siteName: "Nearshore GM",
  keywords:
    "software engineers, software developer, software company, flyeralarm, IT, Nis, Serbia,New Year, 2024, celebration",
  // update this
  description:
    "New Year 2004 celebration at Nearshore GM. We look back on the year behind us.",
  url: "https://www.nearshoregm.com/insights/wrapping-up-the-year-together",
  type: "article",
  ogImage: ogImage,
};
// meta tags end
const BlogPost10 = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page} data-aos="fade-up">
      <MetaTags config={config} />
      <figure className={styles.hero_image_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.filter}></div>
              <Image
                url={header}
                alt={config.title}
                className={`img-fluid ${styles.image}`}
              />
              <div className={styles.info}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className={styles.date_share}>
                        <span className={styles.date}>
                          {config.dateCreated}
                        </span>
                        <ShareLinks shareUrl={config.url} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className={styles.title_holder}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <h1 className={styles.title}>{config.title}</h1>
                      <h3 className={styles.subtitle}>{config.subtitle}</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </figure>
      <div className={styles.post_body}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.wraper}>
                <h2 className={styles.sub_title}>
                  Our office buzzed with holiday spirit at the beginning of
                  December as the team gathered to decor the Christmas tree.
                  Each team member brought their personal touch to our tree, a
                  delightful symbol of the diverse and creative spirit that
                  makes our team truly unique.
                </h2>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body1}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body2}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body3}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  As December unfolded, we celebrated not just the season but
                  also the dedication of two great colleagues marking work
                  anniversaries. Their commitment and hard work were evident in
                  every task they took, reflecting not only their professional
                  growth but also their integral role in shaping our success.
                  These milestones were more than just dates on a calendar, they
                  were a proof of their diligence and team spirit.
                </p>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body4}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body5}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  In the midst of these anniversaries, one of our newest team
                  members earned the title of Employee of the Month and
                  showcased not only proficiency in his role but also a hunger
                  for continuous improvement. It was a proud moment as he
                  appropriated the badge of New Skill Learner, with the highest
                  support of all the colleagues.
                </p>
                <div className={`${styles.body_image_holder} `}>
                  <img
                    src={body6}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  Our team said farewell to the old year and welcomed the new
                  one with a spirited celebration at one of the local places we
                  all like. Nestled in the heart of our tradition, we indulged
                  in a feast of delectable local dishes, savoring the rich
                  flavors and enjoying the vibrant atmosphere.
                </p>
                <div className={`${styles.body_image_holder} `}>
                  <img
                    src={body7}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  The festivities reached their peak with our Secret Santa
                  exchange, a culmination of thoughtfulness and holiday cheer.
                  The office was full with excitement as colleagues exchanged
                  carefully selected gifts, ranging from personalized treasures
                  to items that embodied the true essence of the season. The
                  joyful reactions filled the room and showed the genuine effort
                  everyone put into making the gift giving experience special.
                </p>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body8}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body9}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body10}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body11}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body12}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  Cheers to the incredible journey ahead!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.back_link_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <Link to="/insights" className={styles.back_link}>
                <ArrowLeft className={styles.icon} />
                Back
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogPost10;
