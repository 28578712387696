import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import styles from "./CookiePolicy.module.scss";
import { Helmet, HelmetProvider } from "react-helmet-async";

const CookiePolicy = () => {
  return (
    <div className={styles.page}>
      <HelmetProvider>
        <Helmet>
          <title>Nearshore GM | Cookie Policy</title>
          <meta
            name="keywords"
            content="software engineers, software developer, software company, flyeralarm"
          />
          <meta
            name="description"
            content="This page tells you what you need to know about cookies and how they are used on our website."
          />
          <link
            rel="canonical"
            href="https://www.nearshoregm.com/cookie-policy"
          ></link>
        </Helmet>
      </HelmetProvider>
      <Container>
        <Row>
          <Col md={12}>
            <h1 className={styles.title}>Cookie Policy</h1>
          </Col>
          <Col md={12}>
            <h2 className={styles.subtitle}>Introduction</h2>
            <p className={styles.text}>
              Nearshore GM doo, Bulevar Svetog Cara Konstantina 90, Niš, Serbia,
              may use cookies, web beacons, and other tracking technologies on
              the Website <b>www.nearshoregm.com</b>, to help us customize it
              and improve your experience while visiting.
            </p>
            <p className={styles.text}>
              We encourage you to review this Cookie Policy and stay informed of
              updates periodically. If you have any questions concerning our use
              of your personal information, please email us at:{" "}
              <b>info@nearshoregm.com</b>
            </p>
          </Col>
          <Col md={12}>
            <h2 className={styles.subtitle}>What is Cookie?</h2>
            <p className={styles.text}>
              A “cookie” is a string of information that assigns you a unique
              identifier that we store on your computer. Your browser then
              provides that unique identifier to use each time you submit a
              query to the Website. We use cookies on the Website to keep track
              of services you have used, record registration information, record
              your user preferences, keep you logged into the Website, and track
              the pages you visit. Cookies help us understand how the Website is
              being used and improve your user experience.
            </p>
          </Col>
          <Col md={12}>
            <h2 className={styles.subtitle}>
              What types of Cookies do we use and why?
            </h2>
            <p className={styles.text}>
              The following types of cookies may be used when you visit the
              Website:
            </p>
            <ul className={styles.list}>
              <li className={styles.items}>
                <b>Essential cookies</b> <br />
                These cookies are necessary to the core functionality of our
                Website and some of its features, such as access to secure
                areas.
              </li>
              <Table
                striped
                bordered
                hover
                responsive
                size="sm"
                className="pref_table"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Purpose</th>
                    <th>Type</th>
                    <th>Expires In</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>acceptedCookies</td>
                    <td style={{ maxWidth: 400 }}>
                      The cookie remembers that the user is accepting all
                      cookies.
                    </td>
                    <td>HTTP</td>
                    <td>7 days</td>
                  </tr>
                  <tr>
                    <td>ga_dec</td>
                    <td style={{ maxWidth: 400 }}>
                      This cookie remembers that the user refused to accept
                      analytics cookies.
                    </td>
                    <td>HTTP</td>
                    <td>7 days</td>
                  </tr>
                </tbody>
              </Table>
              <li className={styles.items}>
                <b>Analytics</b> <br />
                These cookies collect information that can help us understand
                how our Websites are being used. <br />
                Our Website uses Google Analytics cookies, which is a type of
                “cookies”. These cookies are used to collect information to
                analyze the traffic to our Website and how visitors are using
                our Website. For example, these cookies may track things such as
                how long you spend on the Website or the pages you visit, which
                helps us to understand how we can improve our Website for you.
                The information collected through these cookies does not
                identify any individual visitor. Google anonymizes the data we
                use for this purpose.
              </li>
              <Table
                striped
                bordered
                hover
                responsive
                size="sm"
                className="pref_table"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Purpose</th>
                    <th>Type</th>
                    <th>Expires In</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>_ga</td>
                    <td style={{ maxWidth: 400 }}>
                      This cookie registers a unique ID that is used to generate
                      statistical data on how the visitor uses the Website.
                    </td>
                    <td>HTTP</td>
                    <td>2 years</td>
                  </tr>
                  <tr>
                    <td>_gid</td>
                    <td style={{ maxWidth: 400 }}>
                      This cookie is installed by Google Analytics. The cookie
                      is used to store information of how visitors use a website
                      and helps in creating an analytics report of how the
                      website is doing.
                    </td>
                    <td>HTTP</td>
                    <td>1 day</td>
                  </tr>
                  <tr>
                    <td>_gat</td>
                    <td>
                      This cookie is associated with Google Analytics. It is
                      used to limit the request rate–limiting the collection of
                      data on high-traffic sites
                    </td>
                    <td>HTTP</td>
                    <td>10 minutes</td>
                  </tr>
                </tbody>
              </Table>
            </ul>
            <p className={styles.text}>
              For more information about Google’s privacy policy in respect of
              Google Analytics, please refer to the Google Analytics Privacy
              Policy:{" "}
              <a
                href="https://support.google.com/analytics/answer/6004245?hl=en"
                target="_blank"
              >
                https://support.google.com/analytics/answer/6004245?hl=en
              </a>
            </p>
            <p className={styles.text}>
              You may opt out of Google Analytics by visiting the following
              link:{" "}
              <a
                href=" https://tools.google.com/dlpage/gaoptout"
                target="_blank"
              >
                {" "}
                https://tools.google.com/dlpage/gaoptout
              </a>
            </p>
            <p className={styles.text}>
              Note that our Website does not use other cookies or software of
              the same or similar type in collecting and processing your
              personal information. If there is a change in this respect, we
              will duly inform you with a statement on the Website.
            </p>
          </Col>
          <Col md={12}>
            <h2 className={styles.subtitle}>How to disable cookies?</h2>
            <p className={styles.text}>
              You can change your cookie preferences at any time by clicking on
              the ‘Cookie preferences’ icon. You can then adjust the available
              checkboxes to ‘On’ or ‘Off’, by clicking ‘Save‘. You may need to
              refresh your page for your settings to take effect.
            </p>

            <p className={styles.text}>
              If you wish to disable cookies, you may use your browser settings.
              Namely, most internet browsers are set up by default to accept
              cookies. However, if you want to refuse or delete them (or similar
              technologies) please refer to the help and support area on your
              browser for instructions on how to block or delete cookies (for
              example Microsoft Edge, Google Chrome, Mozilla Firefox, and Apple
              Safari). Keep in mind that each browser has a different procedure
              for managing and configuring cookies. However, if you use your
              browser settings to block all cookies (including necessary
              cookies) you may not be able to access all or some parts of our
              Website.
            </p>
          </Col>
          <Col md={12}>
            <h2 className={styles.subtitle}>
              What if we change Cookie Policy?
            </h2>
            <p className={styles.text}>
              This Cookie Policy may be updated from time to time. We will not
              explicitly inform our Website users of these changes. Instead, we
              recommend that you check this page occasionally for any policy
              changes. The privacy policy was last updated on June 1, 2022.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CookiePolicy;
