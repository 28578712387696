import React from "react";
import styles from "./Input.module.scss";

const Input = (props) => {
  return (
    <label className={`${styles.label} ${props.labelClassName}`}>
      <span className={styles.label_span}>{props.labelTitle}</span>
      <input
        type={props.type}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        className={`${styles.field} ${props.className}`}
        onChange={props.onChange}
        required={props.required}
      />
    </label>
  );
};

export default Input;
