import React from "react";
import styles from "./Loader.module.scss";

const Loader = () => {
  return (
    <div className={styles.loader_holder}>
      <div className={styles.spinner_box}>
        <div className={styles.configure_border_1}>
          <div className={styles.configure_core}></div>
        </div>
        <div className={styles.configure_border_2}>
          <div className={styles.configure_core}></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
