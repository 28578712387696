import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowLeft } from "react-bootstrap-icons";
import styles from "./BlogPosts.module.scss";
import { Link } from "react-router-dom";
import Image from "../Image/Image";
import ShareLinks from "../ShareLinks/ShareLinks";
import MetaTags from "./MetaTags";
import AOS from "aos";
import "aos/dist/aos.css";

import header from "../../assets/images/blogs/blog9/header.webp";

// meta tags

// og image 1200px X 630px
import ogImage from "../../assets/images/blogs/blog9/og_image.png";

const config = {
  title: "New benefits for new challenges",
  subtitle: "Benefits at Nearshore GM",
  dateCreated: "26.July 2023",
  metaTitle: "Nearshore GM | New benefits for new challenges",
  siteName: "Nearshore GM",
  keywords:
    "software engineers, software developer, software company, flyeralarm, IT, Nis, Serbia,team building,benefits",
  // update this
  description:
    "We strive to create a supportive work environment by offering a comprehensive benefit known as Financial, legal and psychological counseling",
  url: "https://www.nearshoregm.com/insights/new-benefits-for-new-challenges",
  type: "article",
  ogImage: ogImage,
};
// meta tags end
const BlogPost1 = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page} data-aos="fade-up">
      <MetaTags config={config} />
      <figure className={styles.hero_image_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.filter}></div>
              <Image
                url={header}
                alt={config.title}
                className={`img-fluid ${styles.image}`}
              />
              <div className={styles.info}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className={styles.date_share}>
                        <span className={styles.date}>
                          {config.dateCreated}
                        </span>
                        <ShareLinks shareUrl={config.url} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className={styles.title_holder}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <h1 className={styles.title}>{config.title}</h1>
                      <h3 className={styles.subtitle}>{config.subtitle}</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </figure>
      <div className={styles.post_body}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.wraper}>
                <h2 className={`${styles.sub_title} ${styles.fixed_width}`}>
                  “Happiness is not the absence of problems, it’s the ability to
                  deal with them.”
                </h2>
                <p className={styles.text}>
                  In our fast-paced and dynamic world, the demands of everyday
                  life can often become overwhelming. The constant flood of
                  information and the complexities of modern living can leave us
                  feeling stressed and uncertain about various aspects of our
                  lives. Recognizing the challenges our colleagues face, we
                  strive to create a supportive work environment by offering a
                  comprehensive benefit known as Financial, legal and
                  psychological counseling.
                </p>
                <p className={styles.text}>
                  The field of finances can often be complex, especially when
                  dealing with banking documentation or financial planning.
                  Through Financial counseling, our colleagues can seek guidance
                  on managing these financial doubts and rely on experts' advice
                  who can offer valuable insights. By answering all financial
                  queries our colleagues may have, they surely empower them to
                  achieve greater security when making such a decision.
                </p>

                {/* <h4 className={styles.quote}></h4> */}
                <p className={styles.text}>
                  Legal challenges are something that most of the people
                  encounter nowadays, from contractual issues to personal
                  disputes. With Legal counseling, employees have access to
                  expert advice and guidance on any legal matter they may
                  encounter. Whether they have questions about contracts,
                  tenancy agreements, or any other legal concerns, our external
                  collaborators are here to provide clarity and assistance,
                  allowing our colleagues to be well-informed.
                </p>
                <p className={styles.text}>
                  Mental well-being is vital in coping with life's challenges.
                  With Psychological counseling service, we prioritize our
                  colleagues' mental health and offer a safe space for them to
                  express their thoughts and emotions. Professional counselors
                  that we are cooperating with provide confidential support and
                  guidance, helping our colleagues cope with stress, anxiety, or
                  any other psychological issues they may be facing. We believe
                  that a healthy mind is essential for overall well-being and
                  productivity, and we are committed to support this journey
                  toward mental wellness.
                </p>
                <p className={styles.text}>
                  At Nearshore GM we are dedicated to fostering an environment
                  where employees can thrive both personally and professionally.
                  By providing Financial, legal and psychological counseling we
                  aim to ease the burdens of everyday life and promote a
                  positive and supportive workplace where our colleagues can
                  focus on their growth and success. This way, we hope to
                  empower a more balanced and fulfilling way of living and
                  working.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.back_link_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <Link to="/insights" className={styles.back_link}>
                <ArrowLeft className={styles.icon} />
                Back
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogPost1;
