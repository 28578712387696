import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowLeft } from "react-bootstrap-icons";
import styles from "./BlogPosts.module.scss";
import { Link } from "react-router-dom";
import Image from "../Image/Image";
import ShareLinks from "../ShareLinks/ShareLinks";
import MetaTags from "./MetaTags";
import AOS from "aos";
import "aos/dist/aos.css";

import header from "../../assets/images/blogs/blog12/1.webp";
import body2 from "../../assets/images/blogs/blog12/2.webp";
import body3 from "../../assets/images/blogs/blog12/3.webp";
import body4 from "../../assets/images/blogs/blog12/4.webp";
import body5 from "../../assets/images/blogs/blog12/5.webp";
import body6 from "../../assets/images/blogs/blog12/6.webp";
import body7 from "../../assets/images/blogs/blog12/7.webp";
import body8 from "../../assets/images/blogs/blog12/8.webp";

// meta tags

// og image 1200px X 630px
import ogImage from "../../assets/images/blogs/blog12/og_image.jpg";

const config = {
  title: "Run for little heroes",
  subtitle: "Stafeta srcem 2024",
  dateCreated: "10.Jun 2024",
  metaTitle: "Nearshore GM | Run for little heroes",
  siteName: "Nearshore GM",
  keywords:
    "software engineers, software developer, software company, flyeralarm, IT, Nis, Serbia, stafeta srcem, nurdor, 2024",
  // update this
  description:
    "Last week, a local company, SYRMIA, organized their third humanitarian run called “Stafeta Srcem,” in which we participated for the first time.",
  url: "https://www.nearshoregm.com/insights/run-for-little-heroes",
  type: "article",
  ogImage: ogImage,
};
// meta tags end
const BlogPost10 = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page} data-aos="fade-up">
      <MetaTags config={config} />
      <figure className={styles.hero_image_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.filter}></div>
              <Image
                url={header}
                alt={config.title}
                className={`img-fluid ${styles.image}`}
              />
              <div className={styles.info}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className={styles.date_share}>
                        <span className={styles.date}>
                          {config.dateCreated}
                        </span>
                        <ShareLinks shareUrl={config.url} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className={styles.title_holder}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <h1 className={styles.title}>{config.title}</h1>
                      <h3 className={styles.subtitle}>{config.subtitle}</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </figure>
      <div className={styles.post_body}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.wraper}>
                <h2 className={`${styles.sub_title} ${styles.fixed_width}`}>
                  “There is no power for change greater than a community
                  discovering what it cares about.”
                </h2>
                <p className={styles.text}>
                  Last week, a local company, SYRMIA, organized their third
                  humanitarian run called “Stafeta Srcem,” in which we
                  participated for the first time. It all started as a simple
                  proposition but quickly became the main topic of conversation
                  in our office. Initially, we thought that running 850 meters
                  would be easy, so we signed up. However, once we looked at the
                  distance on the map, we began to speculate and laugh about all
                  the funny things we might face on the running path.
                </p>
                <p className={styles.text}>
                  Despite our initial concerns, some of us managed to train a
                  few times before the race. Even though one of our colleagues
                  fell ill and the backup runner injured their knee the day
                  before the event, we gather a team of five. Marko, Milan,
                  Boris, Milica, and Marko rose to the occasion.
                </p>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body2}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body3}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body4}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body7}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  Not only did we survive the run, but we also exceeded our
                  expectations by winning our first 71st place. We were all
                  extremely proud of our colleagues who ran and gave their best.
                  After the race, we enjoyed an amazing time with our families,
                  who came to support us, and mingled with other participants
                  from different companies.
                </p>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body5}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body7}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body6}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  It was incredible to see the community come together to
                  support such an important cause – the National Association of
                  Parents of Children with Cancer. This race felt like our first
                  training session for next year, and we are already looking
                  forward to participating again. Being part of an event with
                  such a significant goal was truly inspiring, and we can’t wait
                  for next year’s race.
                </p>
                <div className={`${styles.body_image_holder} `}>
                  <img
                    src={body8}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.back_link_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <Link to="/insights" className={styles.back_link}>
                <ArrowLeft className={styles.icon} />
                Back
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogPost10;
