import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowLeft } from "react-bootstrap-icons";
import styles from "./BlogPosts.module.scss";
import { Link } from "react-router-dom";
import Image from "../Image/Image";
import ShareLinks from "../ShareLinks/ShareLinks";
import MetaTags from "./MetaTags";
import AOS from "aos";
import "aos/dist/aos.css";

import header from "../../assets/images/blogs/blog1/header.webp";
import body1 from "../../assets/images/blogs/blog1/body1.webp";
import body2 from "../../assets/images/blogs/blog1/body2.webp";
import body3 from "../../assets/images/blogs/blog1/body3.webp";
// meta tags

// og image 1200px X 630px
import ogImage from "../../assets/images/blogs/blog1/og_image.jpg";

const config = {
  title: "Charging batteries",
  subtitle: "Team building 2021",
  dateCreated: "04.Nov 2021",
  metaTitle: "Nearshore GM | Charging batteries",
  siteName: "Nearshore GM",
  keywords:
    "software engineers, software developer, software company, flyeralarm, IT, Nis, Serbia,team building,",
  // update this
  description:
    "The month of October is dedicated to the organization of Team Building in Nearshore",
  url: "https://www.nearshoregm.com/insights/team-building-2021",
  type: "article",
  ogImage: ogImage,
};
// meta tags end
const BlogPost1 = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page} data-aos="fade-up">
      <MetaTags config={config} />
      <figure className={styles.hero_image_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.filter}></div>
              <Image
                url={header}
                alt={config.title}
                className={`img-fluid ${styles.image}`}
              />
              <div className={styles.info}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className={styles.date_share}>
                        <span className={styles.date}>
                          {config.dateCreated}
                        </span>
                        <ShareLinks shareUrl={config.url} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className={styles.title_holder}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <h1 className={styles.title}>{config.title}</h1>
                      <h3 className={styles.subtitle}>{config.subtitle}</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </figure>
      <div className={styles.post_body}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.wraper}>
                <h2 className={styles.sub_title}>
                  The month of October is dedicated to the organization of Team
                  Building. After gathering in front of our company's
                  headquarters, we headed to Vrnjačka Banja.
                </h2>
                <p className={styles.text}>
                  Hotel "Vrnjačke Terme" was our host this year and we are more
                  than satisfied with the service we received there: starting
                  with accommodation units, meals at the hotel and most
                  importantly, the spa center which contains several pools,
                  slides, jacuzzi, salt room, etc.
                </p>
                <p className={styles.text}>
                  On the second day of our stay at the hotel, we planned joint
                  team-building activities aimed primarily at fun, but also at
                  raising the team spirit and cooperation of our teams..
                </p>
                <div className={styles.body_image_holder}>
                  <img
                    src={body1}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <i>Maximum concentration</i>
                </div>
                <div className={styles.body_image_holder}>
                  <img
                    src={body3}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <i>United in maintaining balance</i>
                </div>
                {/* <h4 className={styles.quote}></h4> */}
                <div className={styles.body_image_holder}>
                  <img
                    src={body2}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <i>Jenga Game Winners!</i>
                </div>
                <p className={styles.text}>
                  Our overall impression of this year's organization is a pure
                  10!
                </p>
                <p className={styles.text}>
                  Eagerly awaiting the next team building, we happily recall
                  these photos and plan new memories together.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.back_link_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <Link to="/insights" className={styles.back_link}>
                <ArrowLeft className={styles.icon} />
                Back
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogPost1;
