import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowLeft } from "react-bootstrap-icons";
import styles from "./BlogPosts.module.scss";
import { Link } from "react-router-dom";
import Image from "../Image/Image";
import ShareLinks from "../ShareLinks/ShareLinks";
import MetaTags from "./MetaTags";
import AOS from "aos";
import "aos/dist/aos.css";

import header from "../../assets/images/blogs/blog7/header.webp";
import body2 from "../../assets/images/blogs/blog7/body2.webp";
import body3 from "../../assets/images/blogs/blog7/body3.webp";
import body4 from "../../assets/images/blogs/blog7/body4.webp";
import body5 from "../../assets/images/blogs/blog7/body5.webp";
import body6 from "../../assets/images/blogs/blog7/body6.webp";
import body7 from "../../assets/images/blogs/blog7/body7.webp";
import body8 from "../../assets/images/blogs/blog7/body8.webp";
import body9 from "../../assets/images/blogs/blog7/body9.webp";
import body10 from "../../assets/images/blogs/blog7/body10.webp";
import body11 from "../../assets/images/blogs/blog7/body11.webp";
import body12 from "../../assets/images/blogs/blog7/body12.webp";
// meta tags
// og image 1200px X 630px
import ogImage from "../../assets/images/blogs/blog7/og_image.jpg";

const config = {
  title: "New Year’s magic at the office",
  subtitle: "",
  dateCreated: "11.Jan 2023",
  metaTitle: "Nearshore GM | New Year’s magic at the office",
  siteName: "Nearshore GM",
  keywords:
    "software engineers, software developer, software company, flyeralarm, IT, Nis, Serbia,new years celebration",
  // update this
  description:
    "What the New Year brings to you depends a good deal on what you bring to the New Year",
  url: "https://www.nearshoregm.com/insights/new-years-celebration-2023",
  type: "article",
  ogImage: ogImage,
};
// meta tags end
const BlogPost1 = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page} data-aos="fade-up">
      <MetaTags config={config} />
      <figure className={styles.hero_image_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.filter}></div>
              <Image
                url={header}
                alt={config.title}
                className={`img-fluid ${styles.image}`}
              />
              <div className={styles.info}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className={styles.date_share}>
                        <span className={styles.date}>
                          {config.dateCreated}
                        </span>
                        <ShareLinks shareUrl={config.url} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className={styles.title_holder}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <h1 className={styles.title}>{config.title}</h1>
                      <h3 className={styles.subtitle}>{config.subtitle}</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </figure>
      <div className={styles.post_body}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.wraper}>
                <h2 className={`${styles.sub_title} ${styles.fixed_width}`}>
                  “What the New Year brings to you depends a good deal on what
                  you bring to the New Year.”
                </h2>
                <p className={styles.text}>
                  The New Year's magic at the Nearshore GM offices has kicked
                  off with a monthly challenge. In December, we were
                  particularly creative and decided to write a song called
                  December at my HUB. Each of us wrote a new verse each day
                  referring to the many spontaneous or planned, fun events. We
                  reminded each other which turn it was and had a lot of fun
                  while waiting for new verses. A real work of art has been
                  created that we are very proud of because it will preserve all
                  the common, exciting situations that happened in 2022. On this
                  occasion, few of us realized that they have a special skill in
                  rhyming and writing, so in 2023 we will certainly have a lot
                  of written creative challenges.
                </p>
                <div className={styles.body_image_holder}>
                  <img
                    src={body8}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  We are guided by the idea that no Christmas tree is small,
                  each one has New Year's magic in it, and ours is especially
                  dear to us because it is decorated with joint effort. One
                  December afternoon, Christmas cookies, chatting and having
                  fun, and our Christmas tree began its daily sparkle in
                  December.
                </p>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body6}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body5}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body7}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  In December, colleagues Milan and Suzana celebrated their
                  working anniversaries and we are very glad that they have been
                  with us for so long. Milan started working as a programmer at
                  the very beginning of the company's work, and the ideas he has
                  for gatherings, the monthly challenges he proposes and the
                  interesting facts he shares with us daily make our everyday
                  life at the office very interesting and different. We are
                  looking forward to the anniversaries that are coming next year
                  and we hope that all the next ones will be as sweet as these.
                </p>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body4}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body3}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                {/* <h4 className={styles.quote}></h4> */}
                <p className={styles.text}>
                  What made December special was our new employee of the month,
                  Boris. Because of all the socializing initiatives he showed in
                  the previous month, support in his work, and especially
                  because of his selfless sharing with us not only of knowledge
                  related to programming, but also knowledge of plants, Boris
                  was awarded this nice merit. Since he joined our team, we have
                  all become much more interested in planting and caring for
                  flowers and thanks to him we had last month's planting
                  challenge, where even a few lemons sprouted and now adorn our
                  office.
                </p>
                <div className={styles.body_image_holder}>
                  <img
                    src={body9}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  Probably the most fun event of the past month was the
                  traditional Secret Santa. This small event is not so important
                  to us because of the gift, but because of the special
                  attention given to each gift purchased. Books, schools, New
                  Year's decorations and clothes, brain teasers say a lot about
                  how well we know each other and how much teamwork and daily
                  socializing contribute to that. We spent good part of the day
                  solving brain teasers that colleagues had as their present.
                </p>{" "}
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body12}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body2}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body10}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body11}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  We wish everyone success and happiness in 2023. We welcome the
                  new year with great wishes for new triumphs and improvement
                  for all people.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.back_link_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <Link to="/insights" className={styles.back_link}>
                <ArrowLeft className={styles.icon} />
                Back
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogPost1;
