import React, { useState } from "react";
import styles from "./Hero.module.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import faLogo from "../../assets/images/FA_Logo-3.svg";
import Image from "../../components/Image/Image";
import Typewriter from "typewriter-effect";
import { CaretDown } from "react-bootstrap-icons";
import { Link } from "react-scroll";

import heroImg from "../../assets/images/hh.svg";

// animation
import whirpool from "../../assets/images/hero_animation/whirpool.png";
import colorfulTriangleBlue from "../../assets/images/hero_animation/colorful_trinagle_blue.png";
import colorfulTriangleRed from "../../assets/images/hero_animation/colorful_trinagle_red.png";
import colorfulTriangleGray from "../../assets/images/hero_animation/colorful_trinagle_gray.png";
import rhombusBlue from "../../assets/images/hero_animation/rhombus_blue.png";
import rhombusRed from "../../assets/images/hero_animation/rhombus_red.png";
import squareBlue from "../../assets/images/hero_animation/square_blue.png";
import squareRed from "../../assets/images/hero_animation/square_red.png";
import oval from "../../assets/images/hero_animation/oval.png";

function Hero() {
  const [hashtag, setHashtag] = useState("");
  return (
    <div className={styles.hero}>
      <div className={styles.hero_info}>
        <Container>
          <Row>
            <Col lg={{ span: 5, order: 1 }} xs={{ span: 12, order: 2 }}>
              <div className={styles.left_hero_holder}>
                <div className={styles.text_holder}>
                  <p className={styles.text}>
                    {/* <span className={styles.hashtag}>{hashtag}</span> */}
                    <Typewriter
                      onInit={(typewriter) => {
                        typewriter
                          .typeString(`Tech is our comfort zone`)
                          .callFunction(() => {
                            setTimeout(function () {
                              setHashtag("#");
                            }, 500);
                          })
                          .start();
                      }}
                      options={{
                        autoStart: true,

                        delay: 60,
                        deleteSpeed: 40,
                      }}
                    />
                  </p>
                </div>
                <div className={styles.sub_hero_text}>
                  <p style={{ color: "#333" }}>
                    We are dedicated to delivering clean and efficient solutions
                    and services for the respectful partner, with integrity and
                    security, using modern, proven and up-to-date technologies
                  </p>
                </div>
                <div className={styles.partner}>
                  <p className={styles.text}>Official IT Partner of </p>
                  <Image
                    url={faLogo}
                    alt="flyeralarm"
                    className={`img-fluid ${styles.fa_logo}`}
                  />
                </div>
              </div>
            </Col>
            <Col lg={{ span: 7, order: 2 }} xs={{ span: 12, order: 1 }}>
              <div className={styles.abstract_animation_holder}>
                <img
                  src={whirpool}
                  alt="animation"
                  className={`${styles.ani_element} ${styles.whirpool}`}
                />
                <img
                  src={colorfulTriangleBlue}
                  alt="animation"
                  className={`${styles.ani_element} ${styles.colorfulTriangleBlue}`}
                />
                <img
                  src={colorfulTriangleRed}
                  alt="animation"
                  className={`${styles.ani_element} ${styles.colorfulTriangleRed}`}
                />
                <img
                  src={colorfulTriangleGray}
                  alt="animation"
                  className={`${styles.ani_element} ${styles.colorfulTriangleGray}`}
                />
                <img
                  src={rhombusBlue}
                  alt="animation"
                  className={`${styles.ani_element} ${styles.rhombusBlue}`}
                />
                <img
                  src={rhombusRed}
                  alt="animation"
                  className={`${styles.ani_element} ${styles.rhombusRed}`}
                />
                <img
                  src={squareBlue}
                  alt="animation"
                  className={`${styles.ani_element} ${styles.squareBlue}`}
                />
                <img
                  src={squareRed}
                  alt="animation"
                  className={`${styles.ani_element} ${styles.squareRed}`}
                />
                <img
                  src={oval}
                  alt="animation"
                  className={`${styles.ani_element} ${styles.oval}`}
                />
                <img
                  src={oval}
                  alt="animation"
                  className={`${styles.ani_element} ${styles.oval2}`}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* event banner */}
      <div className={styles.event_banner} hidden>
        <p className={styles.text}>
          Open House Day - Thursday, June 16, 4:00PM - 6:00PM
        </p>

        <Link to="event" spy={true} smooth={true} className={styles.link}>
          Find Out More <CaretDown className={styles.icon} />
        </Link>
      </div>
      {/* event banner end */}
    </div>
  );
}

export default Hero;
