import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowLeft } from "react-bootstrap-icons";
import styles from "./BlogPosts.module.scss";
import { Link } from "react-router-dom";
import Image from "../Image/Image";
import ShareLinks from "../ShareLinks/ShareLinks";
import MetaTags from "./MetaTags";
import AOS from "aos";
import "aos/dist/aos.css";

import header from "../../assets/images/blogs/blog8/header.webp";
import body1 from "../../assets/images/blogs/blog8/body1.webp";
import body2 from "../../assets/images/blogs/blog8/body2.webp";
import body3 from "../../assets/images/blogs/blog8/body3.webp";
import body4 from "../../assets/images/blogs/blog8/body4.webp";
import body5 from "../../assets/images/blogs/blog8/body5.webp";
import body6 from "../../assets/images/blogs/blog8/body6.webp";
import body7 from "../../assets/images/blogs/blog8/body7.webp";
import body8 from "../../assets/images/blogs/blog8/body8.webp";

// meta tags
// og image 1200px X 630px
import ogImage from "../../assets/images/blogs/blog8/og_image.jpg";

const config = {
  title: "May the Mindfulness be with you",
  subtitle: "Nurturing Mental Health in May",
  dateCreated: "25.May 2023",
  metaTitle: "Nearshore GM | May the Mindfulness be with you",
  siteName: "Nearshore GM",
  keywords:
    "software engineers, software developer, software company, web development, flyeralarm, nearshore, IT, Nis, Serbia, mental health",
  // update this
  description:
    "In honor of Mental Health Month, NearshoreGM dedicated a day to create a safe and enjoyable space for relaxation and laughter once again this year.",
  url: "https://www.nearshoregm.com/insights/may-the-mindfulness-be-with-you",
  type: "article",
  ogImage: ogImage,
};
// meta tags end
const BlogPost1 = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page} data-aos="fade-up">
      <MetaTags config={config} />
      <figure className={styles.hero_image_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.filter}></div>
              <Image
                url={header}
                alt={config.title}
                className={`img-fluid ${styles.image}`}
              />
              <div className={styles.info}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className={styles.date_share}>
                        <span className={styles.date}>
                          {config.dateCreated}
                        </span>
                        <ShareLinks shareUrl={config.url} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className={styles.title_holder}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <h1 className={styles.title}>{config.title}</h1>
                      <h3 className={styles.subtitle}>{config.subtitle}</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </figure>
      <div className={styles.post_body}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.wraper}>
                <h2 className={`${styles.sub_title} ${styles.fixed_width}`}>
                  “What mental health needs is more sunlight, more candor and
                  more unashamed conversation.”
                </h2>
                <i style={{ textAlign: "center",display:"block" }}>- Glenn Close</i>
                <br />
                <br />
                <p className={styles.text}>
                  In honor of Mental Health Month, we dedicated a day to create
                  a safe and enjoyable space for relaxation and laughter once
                  again this year. Building positive relationships among
                  colleagues is an integral part of our company's tradition, and
                  we continuously work on upgrading these connections. Fostering
                  a supportive work environment benefits both individual mental
                  health, and overall productivity, creating a strong sense of
                  community among us.
                </p>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body1}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body2}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  Our Mental Health Month celebration was filled with an array
                  of engaging activities that brought our team closer together.
                  Some of our talented colleagues, who happen to be great chess
                  players, contributed greatly to the revival of chess within
                  our office. As we gathered around the chessboard, we not only
                  enjoyed friendly matches but also had the opportunity to learn
                  new strategies and improve our critical thinking skills.
                  Alongside chess, we also engaged in spirited battles of Jenga
                  and Clash of the ships, creating a fun and competitive
                  atmosphere that fostered teamwork and collaboration. Good idea
                  to recharge.
                </p>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body3}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body4}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                {/* <h4 className={styles.quote}></h4> */}
                <p className={styles.text}>
                  One of the highlights was our traditional game of Association.
                  This game never fails to bring endless laughter and amusement
                  to our team, regardless if there's a word that no one knows or
                  it’s the one that is obvious. It’s still hard to think of an
                  explanation that will help your teammate to get it right, so
                  you don’t have to repeat the exact one that another colleague
                  told just one turn before you, as some of us did. Or
                  explaining so hard some words and realizing that is the wrong
                  one, when it’s too late. With written words on paper that
                  we've been thinking all day, and pantomiming as our tools, we
                  embarked on a journey of creativity and laughter. Association
                  has become a cherished tradition within our company, reminding
                  us of the importance of fun and friendship in maintaining
                  positive mental well- being.
                </p>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body5}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body6}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body7}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  May the spirit of mental well-being continue to guide us,
                  fostering a supportive and compassionate work environment
                  where everyone's mental health is cherished and nurtured
                  year-round.
                </p>{" "}
                <div className={styles.body_image_holder}>
                  <img
                    src={body8}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.back_link_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <Link to="/insights" className={styles.back_link}>
                <ArrowLeft className={styles.icon} />
                Back
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogPost1;
