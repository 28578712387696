import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import PreferencesModal from "../PreferencesModal/PreferencesModal";
import styles from "./CookiesLegalText.module.scss";

const CookiesLegalText = ({
  cookieAccepted,
  setCookieAccepted,
  gCookieAccepted,
  setGCookieAccepted,
}) => {
  const [prefModal, setPrefModal] = useState({
    open: false,
  });
  const [display, setDisplay] = useState("none");

  const acceptCookies = () => {
    Cookies.set("acceptedCookies", "true", { expires: 14 });
    setCookieAccepted(true);
  };
  const gCookieDecline = (value) => {
    if (value) {
      setGCookieAccepted(true);
    } else if (value === false) {
      Cookies.set("ga_dec", "true", { expires: 7 });
      setGCookieAccepted(false);
    }
  };

  useEffect(() => {
    if (Cookies.get("ga_dec") === "true") {
      setGCookieAccepted(false);
    } else {
      setGCookieAccepted(true);
    }
  }, [gCookieAccepted]);

  useEffect(() => {
    if (Cookies.get("acceptedCookies") === "true") {
      setCookieAccepted(true);
    } else {
      setCookieAccepted(false);
    }
  }, [cookieAccepted]);
  const UI = () => {
    return (
      <div className={styles.cookies_text}>
        <p className={styles.text}>
          We use cookies to improve user experience and analyze Website traffic.
          By clicking “Accept all cookies“, you agree to our Website's cookie
          use as described in our Cookie Policy. You can change your cookie
          settings by clicking “Cookies Preferences”.
        </p>
        <hr className={styles.hr} />
        <div className={styles.read_more_holder}>
          <ul className={styles.list}>
            Read More:
            <li className={styles.items}>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li className={styles.items}>
              <Link to="/cookie-policy">Cookie Policy</Link>
            </li>
          </ul>
        </div>
        <div className={styles.buttons_holder}>
          <span className={styles.button} onClick={acceptCookies}>
            Accept all cookies
          </span>
          <span
            className={styles.preferences_btn}
            onClick={() =>
              setPrefModal({
                open: true,
              })
            }
          >
            Cookies preferences
          </span>
        </div>
      </div>
    );
  };
  setTimeout(() => {
    setDisplay("block");
  }, 2000);
  return (
    <div style={{ display: `${display}` }}>
      <PreferencesModal
        open={prefModal.open}
        setModal={setPrefModal}
        gCookieDecline={gCookieDecline}
        acceptCookies={acceptCookies}
        cookieAccepted={cookieAccepted}
      />
      {cookieAccepted ? null : UI()}
    </div>
  );
};

export default CookiesLegalText;
