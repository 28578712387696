import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowLeft } from "react-bootstrap-icons";
import styles from "./BlogPosts.module.scss";
import { Link } from "react-router-dom";
import Image from "../Image/Image";
import ShareLinks from "../ShareLinks/ShareLinks";
import MetaTags from "./MetaTags";
import AOS from "aos";
import "aos/dist/aos.css";

import header from "../../assets/images/blogs/blog13/header.webp";
import body2 from "../../assets/images/blogs/blog13/body2.webp";
import body3 from "../../assets/images/blogs/blog13/body3.webp";
import body4 from "../../assets/images/blogs/blog13/body4.webp";
import body5 from "../../assets/images/blogs/blog13/body5.webp";
import body6 from "../../assets/images/blogs/blog13/body6.webp";
import body7 from "../../assets/images/blogs/blog13/body7.webp";
import body8 from "../../assets/images/blogs/blog13/body8.webp";
import body9 from "../../assets/images/blogs/blog13/body9.webp";

// meta tags

// og image 1200px X 630px
import ogImage from "../../assets/images/blogs/blog13/og_image.jpg";

const config = {
  title: "Key learnings from the conference",
  subtitle: "PHP Belgrade 2024",
  dateCreated: "8.Oct 2024",
  metaTitle: "Nearshore GM | Key learnings from the conference",
  siteName: "Nearshore GM",
  keywords:
    "software engineers, software developer, software company, flyeralarm, IT, Nis, Serbia, php, php conference, belgrade 2024",
  // update this
  description:
    "On the 6th and 7th of September 2024, the 9th prestigious international PHP conference was held in Belgrade.",
  url: "https://www.nearshoregm.com/insights/key-learnings-from-conference",
  type: "article",
  ogImage: ogImage,
};
// meta tags end
const BlogPost10 = () => {
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page} data-aos="fade-up">
      <MetaTags config={config} />
      <figure className={styles.hero_image_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.filter}></div>
              <Image
                url={header}
                alt={config.title}
                className={`img-fluid ${styles.image}`}
              />
              <div className={styles.info}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <div className={styles.date_share}>
                        <span className={styles.date}>
                          {config.dateCreated}
                        </span>
                        <ShareLinks shareUrl={config.url} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className={styles.title_holder}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <h1 className={styles.title}>{config.title}</h1>
                      <h3 className={styles.subtitle}>{config.subtitle}</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </figure>
      <div className={styles.post_body}>
        <Container>
          <Row>
            <Col md={12}>
              <div className={styles.wraper}>
                <h2 className={`${styles.sub_title} ${styles.fixed_width}`}>
                  "Conferences are not just an opportunity to learn, but to
                  share ideas, connect with like-minded individuals, and
                  together shape the future of technology."
                </h2>
                <p className={styles.text}>
                  On the 6th and 7th of September 2024, the 9th prestigious
                  international PHP conference was held in Belgrade. It has
                  become a symbol of quality education and the exchange of
                  knowledge in programming, with a special focus on PHP and
                  other modern technologies. The conference gathered developers
                  and technology enthusiasts from around the world, offering a
                  platform to exchange ideas, learn about and explore the latest
                  technologies and trends in the PHP community, and connect with
                  industry experts.
                </p>
                <h2 className={styles.sub_title}>Lectures and workshops</h2>
                <p className={styles.text}>
                  A wide range of content, including various lectures and
                  workshops on PHP 8.3, advanced techniques in Symfony and
                  Laravel frameworks, as well as best practices in security and
                  performance optimization, made the entire conference both
                  interesting and highly valuable. The speakers were leading
                  experts in the PHP community who shared their experiences and
                  insights with the participant
                </p>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body2}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body3}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  The PHP Conference 2024 was highly successful, providing
                  participants not only with new knowledge and tools but also
                  the opportunity to be part of the broader PHP community. With
                  inspiring lectures and workshops, attendees left with many new
                  ideas to apply in their daily work - and so did we.
                </p>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body4}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body5}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <h2 className={styles.sub_title}>Presentations</h2>

                <p className={styles.text}>
                  Inspired by the conference and energized by new ideas, our
                  colleagues Boris, Miljan, Nemanja, and Milica delivered
                  insightful presentations on the lectures they found most
                  interesting. They provided us with thorough and engaging
                  overviews, sharing their key takeaways in a professional yet
                  captivating manner.
                </p>
                <p className={styles.text}>
                  <b>Boris</b> was the first to introduce us with the topic{" "}
                  <b>Monolith / MicroService = Legacy + Appliances</b>, to be
                  exact Living with Legacy Code: Finding Happiness in the Old
                  Code (ExistingCode). In the following, he presented to us that
                  living with legacy code doesn’t have to be a painful
                  experience. He introduced us to some strategies to make
                  working with it more manageable:
                </p>
                <ul className={styles.list}>
                  <li className={styles.items}>
                    Understand Before Changing: It’s crucial to understand the
                    existing logic before diving into refactoring or making
                    updates. The main focus should not just be reading the code
                    - it’s a hard and time-consuming process, but the most
                    rewarding. Legacy systems often embody business-critical
                    operations so that any small changes can have widespread
                    impacts.
                  </li>
                  <li className={styles.items}>
                    Refactor With a Plan: Refactor only if necessary. It’s
                    essential to plan when and what to refactor. Refactor in
                    small chunks. Limiting the scope of refactoring is crucial.
                  </li>
                </ul>
                <p className={styles.text}>
                  Legacy code brings value. Any production code is legacy code,
                  meaning it’s existing, working code.
                </p>
                <div className={`${styles.body_image_holder} `}>
                  <img
                    src={body6}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  Through his presentation, <b>Miljan</b> presented the PHP
                  language to us as a language that can compete with programming
                  languages ​​such as Java and Net. More precisely, the
                  challenge is <b>processing one million rows</b> where a
                  million lines have to be drawn and a string made of them with
                  the help of some other functions instead of the ones
                  originally used, then by using threads and PHP 8 JIT (just in
                  time) the developer managed to lower the initial 35 minutes
                  method execution for 27 seconds.
                </p>
                <div className={`${styles.body_image_holder} `}>
                  <img
                    src={body7}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  <b>Nemanja</b> shared the story of how{" "}
                  <b>
                    our infrastructure has evolved to accommodate an increasing
                    number of users
                  </b>{" "}
                  - from local to cloud and back. How do you build
                  infrastructure for more than just a few users? How do you go
                  from 100 to 1,000 to 100,000 to tens of millions? What happens
                  when hundreds of thousands of users hit your servers
                  simultaneously due to popular demand? His story focused on how
                  a small team of people managed to move software and services
                  from one server to two, then to dozens in the cloud, and then
                  back on-premises. What challenges did we encounter along the
                  way, where we did fall short, and how did we resolve those
                  issues?
                </p>
                <div
                  className={`${styles.body_image_holder} ${styles.multiple}`}
                >
                  <img
                    src={body8}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                  <img
                    src={body9}
                    alt={config.title}
                    className={`${styles.body_image} img-fluid`}
                  />
                </div>
                <p className={styles.text}>
                  The last presentation was reserved for our youngest team
                  member <b>Milica</b>, who discussed <b>Onion architecture</b>{" "}
                  and how applying hexagonal architecture principles can
                  revitalize monolithic systems. Using the metaphor of an onion,
                  she explained the importance of separating the layers of an
                  application to gain a better understanding and create a more
                  organized and flexible code base. Key elements discussed
                  include reducing code bloat, managing dependencies correctly,
                  and implementing appropriate testing.
                </p>
                <p className={styles.text}>
                  Questions and discussions followed the presentation, and we
                  were all pleased to participate. We would like to extend our
                  gratitude to all our colleagues for their hard work and
                  dedication in preparing these briefings. We truly enjoyed
                  every minute of the experience — even our teammates from Legal
                  and HR joined in! Here’s to the next round of learning and
                  sharing!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.back_link_holder}>
        <Container>
          <Row>
            <Col md={12}>
              <Link to="/insights" className={styles.back_link}>
                <ArrowLeft className={styles.icon} />
                Back
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogPost10;
